import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getAllOffers, getOfferStatus, getAllOffersQueryParams } from '@store/reducers/offers/selectors';
import { getOffersAction, deleteOfferAction, cloneOfferAction } from '@store/actions/offers';
import {
  getIsPermissionChecked,
  getPermissionCheckStatus,
  getPermittedPermissions,
} from '@store/reducers/check-permission/selectors';
import { setIsPermissionCheckedAction, checkPermissionAction } from '@store/actions/check-permission';
import Main from './Main';

const mapStateToProps = state => ({
  allOffers: getAllOffers(state),
  queryParams: getAllOffersQueryParams(state),
  offerStatus: getOfferStatus(state),
  permittedPermissions: getPermittedPermissions(state),
  isPermissionChecked: getIsPermissionChecked(state),
  checkPermissionStatus: getPermissionCheckStatus(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getOffersAction,
      deleteOfferAction,
      setIsPermissionCheckedAction,
      checkPermissionAction,
      cloneOfferAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
