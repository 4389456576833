import React, { useEffect } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Card, CardBody, CardHeader } from '@shared/components';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import { ElementLink, ParentLink } from '@screens/static-content/components';
import { Loader } from '@shared/layout-components';
import useMount from '@shared/utils/useMountHook';
import { STATIC_CONTENT_TYPE } from '@screens/static-content/consts';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { Typography } from '@material-ui/core';
import PERMISSIONS from '@shared/consts/permissions';
import { isEmpty } from 'lodash';
import SCREEN_NAMES from '@shared/consts/screenNames';
import { Button } from 'semantic-ui-react';
import useStyles from './styles';

const Main = ({ actions, status, staticContent, permittedPermissions, isPermissionChecked, checkPermissionStatus }) => {
  const styles = useStyles();
  const [t] = useTranslation(TRANSLATIONS.STATIC_CONTENT);

  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  useMount(() => {
    actions.getStaticContentAction();
  });

  const refreshPage = () => actions.getStaticContentAction();

  useEffect(() => {
    if (!isEmpty(permittedPermissions) && !isPermissionChecked?.[SCREEN_NAMES.STATIC_CONTENT]) {
      if (
        !isUserPermitted(PERMISSIONS.STATIC_CONTENT_EDIT_ELEMENT) ||
        !isUserPermitted(PERMISSIONS.GET_STATIC_CONTENT_ELEMENT_BY_ID)
      ) {
        actions.setIsPermissionCheckedAction(SCREEN_NAMES.STATIC_CONTENT);
        actions.checkPermissionAction([
          !isUserPermitted(PERMISSIONS.STATIC_CONTENT_EDIT_ELEMENT) && PERMISSIONS.STATIC_CONTENT_EDIT_ELEMENT,
          !isUserPermitted(PERMISSIONS.GET_STATIC_CONTENT_ELEMENT_BY_ID) &&
            PERMISSIONS.GET_STATIC_CONTENT_ELEMENT_BY_ID,
        ]);
      }
    }
  }, [permittedPermissions]);

  const renderParents = () => {
    return Object?.values(staticContent)?.map(item => {
      if (item.type === STATIC_CONTENT_TYPE.COLLECTION) {
        return <ParentLink item={item} />;
      }

      return null;
    });
  };

  const renderElements = () => {
    return Object?.values(staticContent)?.map(item => {
      if (item.type !== STATIC_CONTENT_TYPE.COLLECTION) {
        return <ElementLink item={item} disabled={!isUserPermitted(PERMISSIONS.GET_STATIC_CONTENT_ELEMENT_BY_ID)} />;
      }

      return null;
    });
  };

  return (
    <Card width="md">
      <CardHeader>
        <Typography className={styles.title}>{t('list')}</Typography>
        <Button color="black" disabled={status === FETCH_STATUS.IDLE} onClick={refreshPage}>
          {t('actionButtons.refresh')}
        </Button>
      </CardHeader>
      <CardBody transparent>
        {status === FETCH_STATUS.IDLE || checkPermissionStatus === FETCH_STATUS.IDLE ? (
          <Loader />
        ) : (
          <div>
            {renderParents()}
            {renderElements()}
          </div>
        )}
      </CardBody>
    </Card>
  );
};

Main.propTypes = {
  actions: shape({}).isRequired,
  staticContent: shape({}),
  status: string,
  permittedPermissions: arrayOf(shape({})),
  isPermissionChecked: shape({}),
  checkPermissionStatus: string,
};

Main.defaultProps = {
  staticContent: {},
  status: undefined,
  permittedPermissions: [],
  isPermissionChecked: {},
  checkPermissionStatus: undefined,
};

export default Main;
