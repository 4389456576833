const API_BASE_URL = window?._env_?.API_BASE_URL;
const GOOGLE_API_KEY = window?._env_?.GOOGLE_API_KEY;
const FRONTEND_SET_PASSWORD_URL_PATTERN = window?._env_?.FRONTEND_SET_PASSWORD_URL_PATTERN;
const MOCK_API_BASE_URL =
  process.env.NODE_ENV === 'development' ? window?._env_?.MOCK_API_BASE_URL_LOCAL : window?._env_?.MOCK_API_BASE_URL;
const CLIENT_SECRET = window?._env_?.CLIENT_SECRET;

const FRONTEND_BASE_URL = window?._env_?.FRONTEND_BASE_URL;

export {
  MOCK_API_BASE_URL,
  API_BASE_URL,
  GOOGLE_API_KEY,
  FRONTEND_SET_PASSWORD_URL_PATTERN,
  CLIENT_SECRET,
  FRONTEND_BASE_URL,
};
