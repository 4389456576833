const OfferSectionElementModel = {
  title: { pl: '' },
  type: '',
  grid: { desktop: 12, tablet: 12, mobile: 12 },
  position: 1,
  userMustBeLoggedIn: false,
  userMustInvest: false,
};

export default OfferSectionElementModel;
