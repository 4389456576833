import React from 'react';
import { Form, Grid, Divider, Dropdown } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import {
  TranslatableTextField,
  TranslatableRichTextField,
  SwitchField,
  InputField,
  DatePickerField,
} from '../../components';
import { OfferBadges, OfferDescriptions, OfferProperties, OfferImage } from './components';

class OfferMainContent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onChangeContent = this.onChangeContent.bind(this);
    this.onChangeSwitch = this.onChangeSwitch.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeNumber = this.onChangeNumber.bind(this);
  }

  onChangeContent(e, { name, value, language }) {
    const { onChange } = this.props;

    return language
      ? onChange(prevState => ({
          offerFields: {
            ...prevState.offerFields,
            [name]: {
              ...prevState.offerFields[name],
              [language]: value,
            },
          },
        }))
      : onChange(prevState => ({ offerFields: { ...prevState.offerFields, [name]: value } }));
  }

  onChangeSwitch(e, { name, checked }) {
    const { onChange } = this.props;

    return onChange(prevState => ({ offerFields: { ...prevState.offerFields, [name]: checked } }));
  }

  onChangeSeries(e, { value }) {
    const { onChange } = this.props;

    return onChange(prevState => ({ offerFields: { ...prevState.offerFields, series: value } }));
  }

  onChangeDate(e, { name, value }) {
    const { onChange } = this.props;

    return onChange(prevState => ({
      offerFields: {
        ...prevState.offerFields,
        [name]: value,
      },
    }));
  }

  onChangeNumber(e, { name, value }) {
    const { onChange } = this.props;

    return value < 0
      ? onChange(prevState => ({
          offerFields: {
            ...prevState.offerFields,
            [name]: 0,
          },
        }))
      : onChange(prevState => ({ offerFields: { ...prevState.offerFields, [name]: value } }));
  }

  render() {
    const {
      offerFields,
      offerBadges,
      offerProperties,
      offerDescriptions,
      offerImage,
      onChange,
      t,
      seriesOptions,
      isViewMode,
    } = this.props;

    return (
      <Form loading={this.props.isLoading}>
        <Form.Group>
          <Grid container stackable columns={2} divided>
            <Grid.Column>
              <SwitchField
                disabled={isViewMode}
                name="templateName"
                label={t('templateName')}
                value={!!offerFields?.templateName}
                onChange={(e, { checked, name }) => {
                  return this.onChangeSwitch(e, { checked: checked ? 'fullWidthGallery' : '', name });
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <SwitchField
                disabled={isViewMode}
                name="isVisible"
                label={t('isVisible')}
                value={offerFields?.isVisible}
                onChange={this.onChangeSwitch}
                tooltip={t('tooltip.isVisible')}
              />
            </Grid.Column>
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={2} divided>
            <TranslatableTextField
              name="title"
              disabled={isViewMode}
              label={t('title')}
              value={offerFields?.title}
              errorMessage={this?.props?.offerErrors?.title}
              icon="font"
              onChange={this.onChangeContent}
              required
            />
            <TranslatableTextField
              disabled={isViewMode}
              name="slug"
              label={t('slug')}
              value={offerFields?.slug}
              icon="linkify"
              onChange={this.onChangeContent}
              errorMessage={this?.props?.offerErrors?.slug}
              required
              tooltip={t('tooltip.slug')}
            />
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={2} divided>
            <DatePickerField
              disabled={isViewMode}
              label={t('developmentStart')}
              icon="calendar alternate outline"
              name="investmentStart"
              onChange={this.onChangeDate}
              value={offerFields?.investmentStart}
              errorMessage={this?.props?.offerErrors?.investmentStart}
              tooltip={t('tooltip.developmentStart')}
            />
            <DatePickerField
              disabled={isViewMode}
              label={t('developmentEnd')}
              icon="calendar alternate"
              name="investmentEnd"
              onChange={this.onChangeDate}
              value={offerFields?.investmentEnd}
              errorMessage={this?.props?.offerErrors?.investmentEnd}
              tooltip={t('tooltip.developmentEnd')}
            />
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={1} divided>
            <TranslatableRichTextField
              disabled={isViewMode}
              name="mainDescription"
              label={t('mainDescription')}
              value={offerFields?.mainDescription}
              errorMessage={this?.props?.offerErrors?.mainDescription}
              onChange={this.onChangeContent}
            />
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={2} divided>
            <TranslatableTextField
              disabled={isViewMode}
              name="projectType"
              label={t('projectType')}
              value={offerFields?.projectType}
              icon="cog"
              errorMessage={this?.props?.offerErrors?.projectType}
              onChange={this.onChangeContent}
            />
            <InputField
              disabled={isViewMode}
              type="number"
              name="position"
              errorMessage={this?.props?.offerErrors?.position}
              label={t('position')}
              value={offerFields?.position}
              icon="signal"
              onChange={this.onChangeNumber}
            />
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={1}>
            <OfferImage
              isViewMode={isViewMode}
              label={t('offerImage')}
              onChange={onChange}
              uploadedImage={offerFields?.imageUrl}
              offerImage={offerImage}
            />
          </Grid>
        </Form.Group>
        <Divider />
        <Grid container columns={1}>
          <OfferBadges
            isViewMode={isViewMode}
            label={t('offerBadges')}
            badges={offerBadges}
            onChange={onChange}
            badgeErrors={this?.props?.offerErrors?.badges}
          />
        </Grid>
        <Divider />
        <Grid container columns={1}>
          <OfferDescriptions
            isViewMode={isViewMode}
            label={t('offerDescriptions')}
            descriptions={offerDescriptions}
            onChange={onChange}
            descriptionErrors={this?.props?.offerErrors?.descriptions}
          />
        </Grid>
        <Divider />
        <Grid container columns={1}>
          <OfferProperties
            isViewMode={isViewMode}
            label={t('offerProperties')}
            properties={offerProperties}
            onChange={onChange}
            propertyErrors={this?.props?.offerErrors?.properties}
          />
        </Grid>
      </Form>
    );
  }
}

export default withTranslation(TRANSLATIONS.OFFERS)(OfferMainContent);
