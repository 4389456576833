import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import useMountWithTranslate from '@shared/utils/useMountWithTranslate';
import { Link, useParams } from 'react-router-dom';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import { Button, Divider, Form, Grid, Loader, Flag, Tab, Menu, Segment } from 'semantic-ui-react';
import { InputField, TranslatableTextField } from '@shared/components/translatable';
import { Card, CardBody, CardHeader, CardTitle, CardToolbar } from '@shared/components';
import { mapErrorCodes } from '@screens/transactions/utils';
import ROUTES from '@shared/consts/routes';
import PERMISSIONS from '@shared/consts/permissions';
import CKEditor from 'ckeditor4-react';
import './styles.scss';
import VariableBadges from './VariableBadges';

const Main = ({
  actions,
  allTemplatesConfig,
  allTemplatesConfigError,
  allTemplatesConfigStatus,
  singleTemplate,
  singleTemplateError,
  singleTemplateStatus,
  permittedPermissions,
}) => {
  const plEditorRef = useRef(null);
  const enEditorRef = useRef(null);
  const [t] = useTranslation(TRANSLATIONS.EMAIL_TEMPLATES);
  const params = useParams();
  const [singleTemplateData, setSingleTemplate] = useState(singleTemplate);
  const [testEmail, setTestEmail] = useState('');
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  const isViewMode = !isUserPermitted(PERMISSIONS.EMAIL_TEMPLATE_EDIT);

  const initTemplate = async () => {
    await actions.getTemplatesConfigAction();
    await actions.getSingleTemplateAction(params.id);
  };

  useEffect(() => {
    setSingleTemplate(singleTemplate);
  }, [singleTemplate]);

  const refreshPage = () => initTemplate();

  useMountWithTranslate(() => {
    initTemplate();
  });

  const saveTemplate = ({ saveAndBack }) => {
    actions.editTemplateAction({
      templateId: params.id,
      name: singleTemplateData.name,
      subject: singleTemplateData.subject,
      body: singleTemplateData?.body,
      saveAndBack,
    });
  };

  const onChangeTranslatableField = (e, { name, language, value }) => {
    setSingleTemplate(prevState => ({ ...prevState, [name]: { ...prevState[name], [language]: value } }));
  };

  const onChangeTestEmail = (e, { value }) => {
    setTestEmail(value);
  };

  const onChangeBodyContent = (content, language) => {
    setSingleTemplate(prevState => ({ ...prevState, body: { ...prevState.body, [language]: content } }));
  };

  const onRestoreDefaultTemplate = language => {
    setSingleTemplate(prevState => ({
      ...prevState,
      body: { ...prevState.body, [language]: singleTemplate?.defaultBody?.[language] },
    }));
  };

  const sendTestEmail = language => {
    actions.sendTestEmailAction({
      type: singleTemplateData?.type,
      subject: singleTemplateData?.subject?.[language],
      email: testEmail,
      body: singleTemplateData?.body?.[language],
    });
  };

  const renderPolishEditor = () => {
    return (
      <div
        className={
          allTemplatesConfigStatus === FETCH_STATUS.LOADING || singleTemplateStatus === FETCH_STATUS.LOADING
            ? 'hidden-editor'
            : ''
        }
      >
        <Segment>
          {isUserPermitted(PERMISSIONS.EMAIL_TEMPLATE_TEST_TEMPLATE) ? (
            <Form className="test-email">
              <Grid container columns={1}>
                <Grid.Column className="text-right">
                  <Button primary onClick={() => onRestoreDefaultTemplate('pl')}>
                    {t('restoreDefaultTemplate')}
                  </Button>
                </Grid.Column>
              </Grid>
              <Grid container stackable columns={2}>
                <InputField
                  flagIcon="pl"
                  name="email"
                  icon="mail"
                  label={t('testEmail')}
                  value={testEmail}
                  onChange={onChangeTestEmail}
                />
                <Grid.Column verticalAlign="bottom" textAlign="right">
                  <Button color="violet" onClick={() => sendTestEmail('pl')}>
                    <Flag name="pl" /> {t('sendTestEmailPl')}
                  </Button>
                </Grid.Column>
              </Grid>
            </Form>
          ) : null}

          <VariableBadges config={allTemplatesConfig?.find?.(config => config?.type === singleTemplateData?.type)} />
        </Segment>
        <CKEditor
          readOnly={isViewMode}
          id="pl"
          ref={plEditorRef}
          data={singleTemplateData?.body?.pl}
          config={{
            fullPage: true,
            allowedContent: true,
            height: 600,
            removePlugins: ['image'],
            extraPlugins: ['justify', 'colorbutton', 'base64image'],
          }}
          onBeforeLoad={ckEditor => {
            ckEditor.plugins.addExternal('base64image', '/ckeditor/plugins/base64image/', 'plugin.js');
          }}
          type="classic"
          onChange={event => {
            const data = event.editor.getData();
            onChangeBodyContent(data, 'pl');
          }}
        />
      </div>
    );
  };

  const handleTabChange = (e, { activeIndex }) => {
    setActiveTabIndex(activeIndex);
  };

  const panes = [
    {
      menuItem: (
        <Menu.Item key="pl">
          <Flag name="pl" /> Polish
        </Menu.Item>
      ),
      pane: <Tab.Pane>{renderPolishEditor()}</Tab.Pane>,
    },
  ];

  return (
    <Card className="admin-orders">
      <CardHeader>
        <CardTitle title={t('mainTitle')} />
        <CardToolbar>
          <div>
            <Button style={{ marginRight: 5 }} as={Link} type="button" to={ROUTES.EMAIL_TEMPLATES}>
              {t('actionButtons.goBack')}
            </Button>
            <Button
              style={{ marginRight: 5 }}
              color="black"
              disabled={
                allTemplatesConfigStatus === FETCH_STATUS.LOADING || singleTemplateStatus === FETCH_STATUS.LOADING
              }
              onClick={refreshPage}
            >
              {t('actionButtons.reload')}
            </Button>
            {isViewMode ? null : (
              <Button
                style={{ marginRight: 5 }}
                disabled={
                  allTemplatesConfigStatus === FETCH_STATUS.LOADING || singleTemplateStatus === FETCH_STATUS.LOADING
                }
                primary
                onClick={saveTemplate}
              >
                {t('actionButtons.save')}
              </Button>
            )}

            {isViewMode ? null : (
              <Button
                disabled={
                  allTemplatesConfigStatus === FETCH_STATUS.LOADING || singleTemplateStatus === FETCH_STATUS.LOADING
                }
                color="facebook"
                onClick={() => saveTemplate({ saveAndBack: true })}
              >
                {t('actionButtons.saveAndBack')}
              </Button>
            )}
          </div>
        </CardToolbar>
      </CardHeader>
      {(allTemplatesConfigError || singleTemplateError) &&
        mapErrorCodes({ errorCode: allTemplatesConfigError || singleTemplateError, t })}
      <CardBody transparent>
        <div className="name-and-subject">
          <Form
            loading={allTemplatesConfigStatus === FETCH_STATUS.LOADING || singleTemplateStatus === FETCH_STATUS.LOADING}
          >
            <Form.Group>
              <Grid container stackable columns={2}>
                <TranslatableTextField
                  disabled={isViewMode}
                  name="name"
                  label={t('name')}
                  value={singleTemplateData?.name}
                  icon="font"
                  onChange={onChangeTranslatableField}
                />
              </Grid>
            </Form.Group>
            <Divider />
            <Form.Group>
              <Grid container stackable columns={2}>
                <TranslatableTextField
                  disabled={isViewMode}
                  name="subject"
                  label={t('subject')}
                  value={singleTemplateData?.subject}
                  icon="font"
                  onChange={onChangeTranslatableField}
                />
              </Grid>
            </Form.Group>
          </Form>
        </div>
        {(allTemplatesConfigStatus === FETCH_STATUS.LOADING || singleTemplateStatus === FETCH_STATUS.LOADING) && (
          <Loader />
        )}
        <Tab renderActiveOnly={false} panes={panes} activeIndex={activeTabIndex} onTabChange={handleTabChange} />
      </CardBody>
    </Card>
  );
};

Main.propTypes = {};

Main.defaultProps = {};

export default Main;
