const GalleryItemModel = {
  position: 1,
  title: { pl: '' },
  description: { pl: '' },
  file: {
    base64: '',
    fileName: '',
    delete: false,
  },
};

export default GalleryItemModel;
