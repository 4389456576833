import React, { useEffect, useMemo } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import useMount from '@shared/utils/useMountHook';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardToolbar,
  NotFoundMessage,
  Message,
  Table, ThumbImage,
} from '@shared/components';
import { Button } from 'semantic-ui-react';
import ROUTES from '@shared/consts/routes';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import PERMISSIONS from '@shared/consts/permissions';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { isEmpty } from 'lodash';
import SCREEN_NAMES from '@shared/consts/screenNames';
import useStyles from './styles';

const Main = ({
                actions,
                sliderItems,
                status,
                queryParams,
                errors,
                permittedPermissions,
                isPermissionChecked,
                checkPermissionStatus,
              }) => {
  const platformLanguage = i18next.language;
  const [t] = useTranslation(TRANSLATIONS.SLIDER);
  const [error] = useTranslation(TRANSLATIONS.ERRORS);
  const styles = useStyles();

  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  useMount(() => {
    actions.getSliderAction();
  });

  useEffect(() => {
    if (!isEmpty(permittedPermissions) && !isPermissionChecked?.[SCREEN_NAMES.SLIDER]) {
      if (
        !isUserPermitted(PERMISSIONS.SLIDER_CREATE) ||
        !isUserPermitted(PERMISSIONS.SLIDER_DELETE) ||
        !isUserPermitted(PERMISSIONS.SLIDER_EDIT) ||
        !isUserPermitted(PERMISSIONS.GET_SLIDER_BY_ID)
      ) {
        actions.setIsPermissionCheckedAction(SCREEN_NAMES.SLIDER);
        actions.checkPermissionAction([
          !isUserPermitted(PERMISSIONS.SLIDER_CREATE) && PERMISSIONS.SLIDER_CREATE,
          !isUserPermitted(PERMISSIONS.SLIDER_DELETE) && PERMISSIONS.SLIDER_DELETE,
          !isUserPermitted(PERMISSIONS.SLIDER_EDIT) && PERMISSIONS.SLIDER_EDIT,
          !isUserPermitted(PERMISSIONS.GET_SLIDER_BY_ID) && PERMISSIONS.GET_SLIDER_BY_ID,
        ]);
      }
    }
  }, [permittedPermissions]);

  const refreshPage = () => actions.getSliderAction();

  const columns = useMemo(
    () => [
      {
        Header: t('image'),
        Cell: ({ row }) => {
          return (
            <ThumbImage
              src={row.original.thumbUrl}
              alt={row.original.name[platformLanguage]}
              isVideo={row.original.fileType === 'video'}
            />
          );
        },
      },
      {
        Header: t('position'),
        accessor: 'position',
        columnName: 'position',
        columnOrder: 'position',
      },
      {
        Header: t('name'),
        columnName: 'name',
        columnOrder: 'name',
        Cell: ({ row }) => {
          return <div>{row.original.name[platformLanguage]}</div>;
        },
      },
      {
        Header: t('active'),
        columnName: 'active',
        columnOrder: 'active',
        Cell: ({ row }) => {
          const isActive = row.original.active;
          return isActive ? t('yes') : t('no');
        },
      },
      {
        accessor: 'actions',
        className: 'text-right',
        Cell: ({ row }) => {
          return (
            <>
              {isUserPermitted(PERMISSIONS.GET_SLIDER_BY_ID) ? (
                <Button as={Link} to={`/slider/${row?.original?.id}`}>
                  {t('actionButtons.edit')}
                </Button>
              ) : null}

              {isUserPermitted(PERMISSIONS.SLIDER_DELETE) ? (
                <Button
                  color="red"
                  onClick={() => {
                    // eslint-disable-next-line no-restricted-globals,no-alert
                    if (confirm(`${t('deleteSliderDisclaimer')} ${row.original.name[platformLanguage]}?`)) {
                      return actions.deleteSliderAction(row?.original?.id);
                    }
                  }}
                >
                  {t('actionButtons.remove')}
                </Button>
              ) : null}
            </>
          );
        },
      },
    ],
    [styles.tableImage],
  );

  const renderTable = () => (
    <Table
      className="table-responsive"
      isLoading={status === FETCH_STATUS.LOADING || checkPermissionStatus === FETCH_STATUS.IDLE}
      data={sliderItems}
      columns={columns}
      queryParams={queryParams}
      searchAction={actions.getSliderAction}
      reloadAction={refreshPage}
      notFoundMessage={
        <NotFoundMessage
          title={t('notFoundSliders')}
          subtitle={t('clickBelow')}
          buttonText={t('create')}
          to={ROUTES.CREATE_SLIDER}
        />
      }
    />
  );

  return (
    <>
      {errors?.map?.(code => (
        <Message danger>{error(code)}</Message>
      ))}
      <Card>
        <CardHeader>
          <CardTitle title={t('mainTitle')}/>
          <CardToolbar>
            <Button disabled={status === FETCH_STATUS.LOADING} onClick={actions.getSliderAction} color="black">
              {t('actionButtons.reload')}
            </Button>

            {isUserPermitted(PERMISSIONS.SLIDER_CREATE) ? (
              <Button as={Link} to={ROUTES.CREATE_SLIDER} primary>
                {t('actionButtons.add')}
              </Button>
            ) : null}
          </CardToolbar>
        </CardHeader>
        <CardBody transparent>{renderTable()}</CardBody>
      </Card>
    </>
  );
};

Main.propTypes = {
  actions: shape({ getSliderAction: func }).isRequired,
  row: shape({}),
  sliderItems: arrayOf(shape({})),
  status: string,
  errors: arrayOf(string),
  queryParams: shape({}),
  permittedPermissions: arrayOf(shape({})),
  isPermissionChecked: shape({}),
  checkPermissionStatus: string,
};

Main.defaultProps = {
  row: {},
  sliderItems: [],
  status: undefined,
  errors: [],
  queryParams: {},
  permittedPermissions: [],
  isPermissionChecked: {},
  checkPermissionStatus: undefined,
};

export default Main;
