import React from 'react';
import { Button, Form, Grid, Segment, Table, Icon, Label, Dropdown } from 'semantic-ui-react';
import { GithubPicker } from 'react-color';
import { TranslatableTextField, InputField } from '../../../components';
import OfferBadgeModel from '../../models/OfferBadgeModel';
import { withTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';

const BADGE_PRESETS = {
  PRESET_1: {
    key: 'preset_1',
    name: 'Preset 1',
    textColor: '#FFFFFF',
    backgroundColor: '#000000',
  },
  PRESET_2: {
    key: 'preset_2',
    name: 'Preset 2',
    textColor: '#000000',
    backgroundColor: '#FFFFFF',
  },
  PRESET_3: {
    key: 'preset_3',
    name: 'Preset 3',
    textColor: '#1273de',
    backgroundColor: '#bed3f3',
  },
  PRESET_4: {
    key: 'preset_4',
    name: 'Preset 4',
    textColor: '#5300eb',
    backgroundColor: '#c4def6',
  },
};

class OfferBadges extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onAddBadge = this.onAddBadge.bind(this);
    this.onRemoveBadge = this.onRemoveBadge.bind(this);
    this.onChangeBadge = this.onChangeBadge.bind(this);
    this.onChangeBadgeTranslatableText = this.onChangeBadgeTranslatableText.bind(this);
  }

  setPreset(editBadgeIndex, preset) {
    const { onChange } = this.props;

    onChange(prevState => ({
      offerBadges: prevState.offerBadges.map((badge, index) =>
        editBadgeIndex === index
          ? {
              ...badge,
              colorText: preset.textColor,
              colorBackground: preset.backgroundColor,
            }
          : badge,
      ),
    }));
  }

  onAddBadge() {
    const { onChange } = this.props;

    onChange(prevState => ({
      offerBadges: prevState.offerBadges.concat([
        {
          ...OfferBadgeModel,
          backendIndex: prevState?.offerBadges[prevState?.offerBadges?.length - 1]?.backendIndex + 1 || 0,
        },
      ]),
    }));
  }

  onRemoveBadge(editBadgeIndex) {
    const { onChange } = this.props;

    onChange(prevState => ({
      offerBadges: prevState.offerBadges.filter((badge, index) => index !== editBadgeIndex),
    }));
  }

  onChangeBadge({ color, name }, editBadgeIndex) {
    const { onChange } = this.props;

    onChange(prevState => ({
      offerBadges: prevState.offerBadges.map((badge, index) =>
        editBadgeIndex === index
          ? {
              ...badge,
              [name]: color,
            }
          : badge,
      ),
    }));
  }

  onChangeBadgeTranslatableText(e, { value, name, language }, editBadgeIndex) {
    const { onChange } = this.props;

    onChange(prevState => ({
      offerBadges: prevState.offerBadges.map((badge, index) =>
        editBadgeIndex === index
          ? {
              ...badge,
              [name]: {
                ...badge[name],
                [language]: value,
              },
            }
          : badge,
      ),
    }));
  }

  render() {
    const { label, badges, badgeErrors, isViewMode, t } = this.props;

    return (
      <Grid.Column>
        <Form.Field>
          <Label size="big" color="black">
            {label}
          </Label>
          <Segment.Group>
            <Segment>
              <Table celled>
                <Table.Body>
                  {badges?.map?.((badge, badgeIndex) => {
                    return (
                      <Table.Row key={badgeIndex} verticalAlign="top">
                        <Table.Cell verticalAlign="middle" collapsing>
                          {badgeIndex + 1}
                        </Table.Cell>
                        <Table.Cell>
                          <Grid stackable columns={1}>
                            <TranslatableTextField
                              disabled={isViewMode}
                              name="text"
                              label="Badge Text"
                              value={badge?.text}
                              errorMessage={badgeErrors?.[badge?.backendIndex]?.text}
                              icon="bookmark outline"
                              onChange={(e, { value, name, language }) =>
                                this.onChangeBadgeTranslatableText(
                                  e,
                                  {
                                    value,
                                    name,
                                    language,
                                  },
                                  badgeIndex,
                                )
                              }
                            />
                          </Grid>
                        </Table.Cell>
                        <Table.Cell>
                          <Grid columns={1}>
                            <Grid.Column>
                              <p>Presets</p>
                              <Dropdown
                                text="Badge color presets"
                                icon="adjust"
                                floating
                                labeled
                                button
                                className="icon"
                                disabled={isViewMode}
                              >
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    style={{ display: 'flex', alignItems: 'center' }}
                                    onClick={() => this.setPreset(badgeIndex, BADGE_PRESETS.PRESET_1)}
                                  >
                                    <div
                                      style={{
                                        margin: '5px',
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: BADGE_PRESETS.PRESET_1.backgroundColor,
                                        border: '1px solid #000000',
                                      }}
                                    />
                                    <div
                                      style={{
                                        margin: '5px',
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: BADGE_PRESETS.PRESET_1.textColor,
                                        border: '1px solid #000000',
                                      }}
                                    />
                                    {BADGE_PRESETS.PRESET_1.name}
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                  <Dropdown.Item
                                    style={{ display: 'flex', alignItems: 'center' }}
                                    onClick={() => this.setPreset(badgeIndex, BADGE_PRESETS.PRESET_2)}
                                  >
                                    <div
                                      style={{
                                        margin: '5px',
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: BADGE_PRESETS.PRESET_2.backgroundColor,
                                        border: '1px solid #000000',
                                      }}
                                    />
                                    <div
                                      style={{
                                        margin: '5px',
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: BADGE_PRESETS.PRESET_2.textColor,
                                        border: '1px solid #000000',
                                      }}
                                    />
                                    {BADGE_PRESETS.PRESET_2.name}
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                  <Dropdown.Item
                                    style={{ display: 'flex', alignItems: 'center' }}
                                    onClick={() => this.setPreset(badgeIndex, BADGE_PRESETS.PRESET_3)}
                                  >
                                    <div
                                      style={{
                                        margin: '5px',
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: BADGE_PRESETS.PRESET_3.backgroundColor,
                                        border: '1px solid #000000',
                                      }}
                                    />
                                    <div
                                      style={{
                                        margin: '5px',
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: BADGE_PRESETS.PRESET_3.textColor,
                                        border: '1px solid #000000',
                                      }}
                                    />
                                    {BADGE_PRESETS.PRESET_3.name}
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                  <Dropdown.Item
                                    style={{ display: 'flex', alignItems: 'center' }}
                                    onClick={() => this.setPreset(badgeIndex, BADGE_PRESETS.PRESET_4)}
                                  >
                                    <div
                                      style={{
                                        margin: '5px',
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: BADGE_PRESETS.PRESET_4.backgroundColor,
                                        border: '1px solid #000000',
                                      }}
                                    />
                                    <div
                                      style={{
                                        margin: '5px',
                                        width: '20px',
                                        height: '20px',
                                        backgroundColor: BADGE_PRESETS.PRESET_4.textColor,
                                        border: '1px solid #000000',
                                      }}
                                    />
                                    {BADGE_PRESETS.PRESET_4.name}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </Grid.Column>
                          </Grid>
                          <Grid columns={1}>
                            <Grid columns={2}>
                              <Grid.Column>
                                <GithubPicker
                                  triangle="hide"
                                  width="auto"
                                  color={badge?.colorText || '#000000'}
                                  onChangeComplete={
                                    isViewMode
                                      ? () => {}
                                      : color => {
                                          this.onChangeBadge({ color: color?.hex, name: 'colorText' }, badgeIndex);
                                        }
                                  }
                                />
                              </Grid.Column>
                              <Grid.Column>
                                <InputField
                                  disabled={isViewMode}
                                  type="text"
                                  name="colorText"
                                  label="Text color"
                                  errorMessage={badgeErrors?.[badge?.backendIndex]?.colorText}
                                  value={badge?.colorText}
                                  icon="adjust"
                                  onChange={(e, { value, name }) =>
                                    this.onChangeBadge({ color: value, name }, badgeIndex)
                                  }
                                />
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  Preview:{' '}
                                  <div
                                    style={{
                                      margin: '5px',
                                      width: '20px',
                                      height: '20px',
                                      backgroundColor: badge?.colorText,
                                      border: '1px solid #000000',
                                    }}
                                  />
                                </div>
                              </Grid.Column>
                            </Grid>
                            <Grid columns={2}>
                              <Grid.Column>
                                <GithubPicker
                                  triangle="hide"
                                  width="auto"
                                  color={badge?.colorBackground || '#000000'}
                                  onChangeComplete={
                                    isViewMode
                                      ? () => {}
                                      : color => {
                                          this.onChangeBadge(
                                            { color: color?.hex, name: 'colorBackground' },
                                            badgeIndex,
                                          );
                                        }
                                  }
                                />
                              </Grid.Column>
                              <Grid.Column>
                                <InputField
                                  disabled={isViewMode}
                                  type="text"
                                  name="colorBackground"
                                  label="Background color"
                                  icon="adjust"
                                  errorMessage={badgeErrors?.[badge?.backendIndex]?.colorBackground}
                                  value={badge?.colorBackground}
                                  onChange={(e, { value, name }) =>
                                    this.onChangeBadge({ color: value, name }, badgeIndex)
                                  }
                                />
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  Preview:{' '}
                                  <div
                                    style={{
                                      margin: '5px',
                                      width: '20px',
                                      height: '20px',
                                      backgroundColor: badge?.colorBackground,
                                      border: '1px solid #000000',
                                    }}
                                  />
                                </div>
                              </Grid.Column>
                            </Grid>
                          </Grid>
                        </Table.Cell>
                        {isViewMode ? null : (
                          <Table.Cell collapsing verticalAlign="middle">
                            <Button icon color="red" onClick={() => this.onRemoveBadge(badgeIndex)}>
                              <Icon name="trash" />
                            </Button>
                          </Table.Cell>
                        )}
                      </Table.Row>
                    );
                  })}
                </Table.Body>
                {isViewMode ? null : (
                  <Table.Footer>
                    <Table.Row>
                      <Table.HeaderCell colSpan="5" textAlign="right">
                        <Button primary onClick={this.onAddBadge}>
                          {t('addBadge')}
                        </Button>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                )}
              </Table>
            </Segment>
          </Segment.Group>
        </Form.Field>
      </Grid.Column>
    );
  }
}

export default withTranslation(TRANSLATIONS.OFFERS)(OfferBadges);
