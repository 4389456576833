import React from 'react';
import { Flag, Form, Grid, Input, Label, TextArea } from 'semantic-ui-react';
import TRANSLATIONS from '@translations/translationNamespaces';
import { withTranslation } from 'react-i18next';

class InputField extends React.PureComponent {
  renderInput = () => {
    const {
      name,
      value,
      onChange,
      type,
      disabled,
      fluid,
      icon,
      iconPosition,
      placeholder,
      disableIcon,
      flagIcon,
      onKeyUp,
      onBlur,
    } = this.props;

    return (
      <Input
        icon={disableIcon ? false : icon}
        iconPosition={disableIcon ? false : iconPosition || 'left'}
        fluid={fluid}
        disabled={disabled}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onKeyUp={onKeyUp}
        onBlur={onBlur}
      />
    );
  };

  renderTextArea = () => {
    const { onChange, name, value, rows, onKeyUp, onBlur, disabled } = this.props;

    return (
      <TextArea
        disabled={disabled}
        onChange={onChange}
        onKeyUp={onKeyUp}
        onBlur={onBlur}
        name={name}
        value={value}
        rows={rows}
      />
    );
  };

  render() {
    const { label, errorMessage, multiline, className, flagIcon, t } = this.props;

    return (
      <Grid.Column className={className}>
        <Form.Field>
          {errorMessage && (
            <Label color="red" className="mb-5 d-flex" style={{ width: 'fit-content' }}>
              {t(errorMessage)}
            </Label>
          )}
          <label>
            {flagIcon && <Flag name={flagIcon} />}
            {label}
          </label>
          {multiline ? this.renderTextArea() : this.renderInput()}
        </Form.Field>
      </Grid.Column>
    );
  }
}

export default withTranslation(TRANSLATIONS.ERRORS)(InputField);
