import React from 'react';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import { shape, string } from 'prop-types';
import moment from 'moment';
import OfferForm from '../NewOfferForm/OfferForm';

class Main extends React.PureComponent {
  componentDidMount() {
    const { actions } = this.props;
    actions.getSeriesOptionsAction();
    actions.getTypesAndTargetsAction();
  }

  render() {
    const { actions, offerStatus, offerErrors, seriesOptions, targets, types } = this.props;
    const offerModel = {
      offerFields: {
        slug: '',
        title: '',
        mainDescription: '',
        projectType: '',
        address: '',
        isOpen: '',
        isVisible: false,
        totalCost: 0,
        costPerStock: 0,
        minStock: 0,
        investmentStart: moment().format('YYYY-MM-DD'),
        investmentEnd: moment().format('YYYY-MM-DD'),
        imageUrl: '',
        thumbUrl: '',
        investmentReturn: { pl: '' },
        fixedInvestorsEquity: '',
        templateName: '',
      },
      investorAttachments: [],
      agreements: [],
      sections: [],
      badges: [],
      properties: [],
      descriptions: [],
      offerImage: { delete: false },
    };

    return (
      <div>
        <OfferForm
          isLoading={offerStatus === FETCH_STATUS.LOADING}
          offer={offerModel}
          offerErrors={offerErrors}
          onCreateOffer={actions.createOfferAction}
          isCreateMode
          agreementTargets={targets}
          agreementTypes={types}
          seriesOptions={seriesOptions}
        />
      </div>
    );
  }
}

Main.propTypes = {
  actions: shape({}),
  singleOffer: shape({}),
  offerStatus: string,
};

Main.defaultProps = {
  actions: {},
  singleOffer: {},
  offerStatus: '',
};

export default Main;
