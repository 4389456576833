import React, { useEffect, useMemo } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import useMountWithTranslate from '@shared/utils/useMountWithTranslate';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import { Loader } from '@shared/layout-components';
import { Card, CardBody, CardHeader, CardTitle, CardToolbar, Table } from '@shared/components';
import i18next from 'i18next';
import PERMISSIONS from '@shared/consts/permissions';
import { isEmpty } from 'lodash';
import SCREEN_NAMES from '@shared/consts/screenNames';

const Main = ({
  actions,
  featuredOffers,
  status,
  permittedPermissions,
  isPermissionChecked,
  checkPermissionStatus,
}) => {
  const [t] = useTranslation(TRANSLATIONS.FEATURED_OFFERS);

  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  useMountWithTranslate(() => {
    actions.getFeaturedOffersAction();
  });

  useEffect(() => {
    if (!isEmpty(permittedPermissions) && !isPermissionChecked?.[SCREEN_NAMES.FEATURED_OFFERS]) {
      if (!isUserPermitted(PERMISSIONS.FEATURED_OFFER_EDIT) || !isUserPermitted(PERMISSIONS.GET_FEATURED_OFFER_BY_ID)) {
        actions.setIsPermissionCheckedAction(SCREEN_NAMES.FEATURED_OFFERS);
        actions.checkPermissionAction([
          !isUserPermitted(PERMISSIONS.FEATURED_OFFER_EDIT) && PERMISSIONS.FEATURED_OFFER_EDIT,
          !isUserPermitted(PERMISSIONS.GET_FEATURED_OFFER_BY_ID) && PERMISSIONS.GET_FEATURED_OFFER_BY_ID,
        ]);
      }
    }
  }, [permittedPermissions]);

  const refreshPage = () => actions.getFeaturedOffersAction();

  const columns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
        Cell: ({ row }) => <span>{t(row?.original?.name)}</span>,
      },
      {
        Header: t('title'),
        accessor: 'title',
        Cell: ({ row }) => <span>{row?.original?.title?.[i18next.language]}</span>,
      },
      {
        accessor: 'actions',
        className: 'text-right',
        Cell: ({ row }) =>
          isUserPermitted(PERMISSIONS.GET_FEATURED_OFFER_BY_ID) ? (
            <Button as={Link} to={`/featured-offers/${row?.original?.id}`}>
              {t('actionButtons.edit')}
            </Button>
          ) : null,
      },
    ],
    [t],
  );

  const renderContent = () =>
    status === FETCH_STATUS.IDLE || checkPermissionStatus === FETCH_STATUS.IDLE ? (
      <Loader form />
    ) : (
      <Table hideFilters hideFooter data={featuredOffers} columns={columns} />
    );

  return (
    <Card>
      <CardHeader>
        <CardTitle title={t('mainTitle')} />
        <CardToolbar>
          <Button disabled={status === FETCH_STATUS.IDLE} onClick={refreshPage} color="black">
            {t('actionButtons.reload')}
          </Button>
        </CardToolbar>
      </CardHeader>
      <CardBody transparent>{renderContent()}</CardBody>
    </Card>
  );
};

Main.propTypes = {
  actions: shape({}).isRequired,
  row: shape({}),
  featuredOffers: arrayOf(shape({})),
  permittedPermissions: arrayOf(shape({})),
  status: string,
  isPermissionChecked: shape({}),
  checkPermissionStatus: string,
};

Main.defaultProps = {
  row: {},
  featuredOffers: [],
  permittedPermissions: [],
  status: undefined,
  isPermissionChecked: {},
  checkPermissionStatus: undefined,
};

export default Main;
