import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Button, Form, Grid, Segment, Table, Icon, Label, Checkbox } from 'semantic-ui-react';
import TRANSLATIONS from '@translations/translationNamespaces';
import { withTranslation } from 'react-i18next';
import { TranslatableTextField, TranslatableRichTextField, InputField } from '../../../components';
import OfferDescriptionModel from '../../models/OfferDescriptionModel';

class OfferDescriptions extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onAddDescription = this.onAddDescription.bind(this);
    this.onRemoveDescription = this.onRemoveDescription.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onChangeDescriptionTranslatableText = this.onChangeDescriptionTranslatableText.bind(this);
  }

  onAddDescription() {
    const { onChange } = this.props;

    onChange(prevState => ({
      offerDescriptions: prevState.offerDescriptions.concat([
        {
          ...OfferDescriptionModel,
          backendIndex:
            Number(prevState?.offerDescriptions?.[prevState?.offerDescriptions?.length - 1]?.backendIndex) + 1 || 0,
          position: Number(prevState?.offerDescriptions?.[prevState?.offerDescriptions?.length - 1]?.position) + 1 || 1,
        },
      ]),
    }));
  }

  onRemoveDescription(editDescriptionIndex) {
    const { onChange } = this.props;

    onChange(prevState => ({
      offerDescriptions: prevState.offerDescriptions.filter((description, index) => index !== editDescriptionIndex),
    }));
  }

  onChangeDescription(e, { value, name }, editDescriptionIndex) {
    const { onChange } = this.props;

    onChange(prevState => ({
      offerDescriptions: prevState.offerDescriptions.map((description, index) =>
        editDescriptionIndex === index
          ? {
              ...description,
              [name]: value,
            }
          : description,
      ),
    }));
  }

  onCheckboxChange(e, { name }, editDescriptionIndex) {
    const { onChange } = this.props;

    onChange(prevState => ({
      offerDescriptions: prevState.offerDescriptions.map((description, index) =>
        editDescriptionIndex === index
          ? {
              ...description,
              [name]: !description[name],
            }
          : description,
      ),
    }));
  }

  onChangeDescriptionTranslatableText(e, { value, name, language }, editDescriptionIndex) {
    const { onChange } = this.props;

    onChange(prevState => ({
      offerDescriptions: prevState.offerDescriptions.map((description, index) =>
        editDescriptionIndex === index
          ? {
              ...description,
              [name]: {
                ...description[name],
                [language]: value,
              },
            }
          : description,
      ),
    }));
  }

  render() {
    const { label, descriptions, descriptionErrors, isViewMode, t } = this.props;

    return (
      <Grid.Column>
        <Form.Field>
          <Label size="big" color="black">
            {label}
          </Label>
          <Segment.Group>
            <Segment>
              <Table celled className="offer-descriptions">
                <Table.Body>
                  {descriptions?.map?.((description, descriptionIndex) => {
                    return (
                      <Table.Row key={descriptionIndex} verticalAlign="top">
                        <Table.Cell>
                          <InputField
                            disabled={isViewMode}
                            icon="numbered list"
                            type="number"
                            name="position"
                            errorMessage={descriptionErrors?.[description?.backendIndex]?.position}
                            label="Position"
                            value={description?.position}
                            onChange={(e, { value, name }) =>
                              this.onChangeDescription(
                                e,
                                {
                                  value,
                                  name,
                                },
                                descriptionIndex,
                              )
                            }
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Grid stackable columns={1}>
                            <TranslatableTextField
                              disabled={isViewMode}
                              icon="header"
                              name="title"
                              errorMessage={descriptionErrors?.[description?.backendIndex]?.title}
                              label="Description Title"
                              value={description?.title}
                              onChange={(e, { value, name, language }) =>
                                this.onChangeDescriptionTranslatableText(
                                  e,
                                  {
                                    value,
                                    name,
                                    language,
                                  },
                                  descriptionIndex,
                                )
                              }
                            />
                          </Grid>
                        </Table.Cell>
                        <Table.Cell>
                          <Grid stackable columns={1}>
                            <TranslatableRichTextField
                              disabled={isViewMode}
                              name="content"
                              errorMessage={descriptionErrors?.[description?.backendIndex]?.content}
                              label="Description Content"
                              value={description?.content}
                              onChange={(e, { value, name, language }) =>
                                this.onChangeDescriptionTranslatableText(
                                  e,
                                  {
                                    value,
                                    name,
                                    language,
                                  },
                                  descriptionIndex,
                                )
                              }
                            />
                          </Grid>
                        </Table.Cell>
                        {isViewMode ? null : (
                          <Table.Cell verticalAlign="middle">
                            <Button color="red" icon onClick={() => this.onRemoveDescription(descriptionIndex)}>
                              <Icon name="trash" />
                            </Button>
                          </Table.Cell>
                        )}
                      </Table.Row>
                    );
                  })}
                </Table.Body>
                {isViewMode ? null : (
                  <Table.Footer>
                    <Table.Row>
                      <Table.HeaderCell colSpan="5" textAlign="right">
                        <Button primary onClick={this.onAddDescription}>
                          {t('addDescription')}
                        </Button>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                )}
              </Table>
            </Segment>
          </Segment.Group>
        </Form.Field>
      </Grid.Column>
    );
  }
}

OfferDescriptions.propTypes = {
  t: func.isRequired,
  onChange: func,
  label: string,
  descriptions: arrayOf(shape({})),
  descriptionErrors: shape({}),
  isViewMode: bool,
};

OfferDescriptions.defaultProps = {
  onChange: () => {},
  label: '',
  descriptions: [],
  descriptionErrors: {},
  isViewMode: false,
};

export default withTranslation(TRANSLATIONS.OFFERS)(OfferDescriptions);
