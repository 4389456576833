import React from 'react';
import { Button, Form, Grid, Segment, Table, Icon, Label, Checkbox } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { TranslatableTextField, InputField } from '../../../components';
import OfferPropertyModel from '../../models/OfferPropertyModel';

class OfferProperties extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onAddProperty = this.onAddProperty.bind(this);
    this.onRemoveProperty = this.onRemoveProperty.bind(this);
    this.onChangeProperty = this.onChangeProperty.bind(this);
    this.onChangePropertyTranslatableText = this.onChangePropertyTranslatableText.bind(this);
  }

  onAddProperty() {
    const { onChange } = this.props;

    onChange(prevState => ({
      offerProperties: prevState.offerProperties.concat([
        {
          ...OfferPropertyModel,
          backendIndex:
            Number(prevState?.offerProperties[prevState?.offerProperties?.length - 1]?.backendIndex) + 1 || 0,
          position: Number(prevState?.offerProperties[prevState?.offerProperties?.length - 1]?.position) + 1 || 1,
        },
      ]),
    }));
  }

  onRemoveProperty(editPropertyIndex) {
    const { onChange } = this.props;

    onChange(prevState => ({
      offerProperties: prevState.offerProperties.filter((property, index) => index !== editPropertyIndex),
    }));
  }

  onChangeProperty(e, { value, name }, editPropertyIndex) {
    const { onChange } = this.props;

    onChange(prevState => ({
      offerProperties: prevState.offerProperties.map((property, index) =>
        editPropertyIndex === index
          ? {
              ...property,
              [name]: value,
            }
          : property,
      ),
    }));
  }

  onCheckboxChange(e, { name }, editPropertyIndex) {
    const { onChange } = this.props;

    onChange(prevState => ({
      offerProperties: prevState.offerProperties.map((property, index) =>
        editPropertyIndex === index
          ? {
              ...property,
              [name]: !property[name],
            }
          : property,
      ),
    }));
  }

  onChangePropertyTranslatableText(e, { value, name, language }, editPropertyIndex) {
    const { onChange } = this.props;

    onChange(prevState => ({
      offerProperties: prevState.offerProperties.map((property, index) =>
        editPropertyIndex === index
          ? {
              ...property,
              [name]: {
                ...property[name],
                [language]: value,
              },
            }
          : property,
      ),
    }));
  }

  render() {
    const { label, properties, propertyErrors, isViewMode, t } = this.props;

    return (
      <Grid.Column>
        <Form.Field>
          <Label size="big" color="black">
            {label}
          </Label>
          <Segment.Group>
            <Segment>
              <Table celled className="offer-properties">
                <Table.Body>
                  {properties?.map?.((property, propertyIndex) => {
                    return (
                      <Table.Row key={propertyIndex} verticalAlign="top">
                        <Table.Cell>
                          <InputField
                            disabled={isViewMode}
                            icon="numbered list"
                            type="number"
                            name="position"
                            errorMessage={propertyErrors?.[property?.backendIndex]?.position}
                            label="Position"
                            value={property?.position}
                            onChange={(e, { value, name }) =>
                              this.onChangeProperty(
                                e,
                                {
                                  value,
                                  name,
                                },
                                propertyIndex,
                              )
                            }
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Grid stackable columns={1}>
                            <TranslatableTextField
                              disabled={isViewMode}
                              icon="sticky note"
                              name="label"
                              errorMessage={propertyErrors?.[property?.backendIndex]?.label}
                              label="Property Label"
                              value={property?.label}
                              onChange={(e, { value, name, language }) =>
                                this.onChangePropertyTranslatableText(
                                  e,
                                  {
                                    value,
                                    name,
                                    language,
                                  },
                                  propertyIndex,
                                )
                              }
                            />
                          </Grid>
                        </Table.Cell>
                        <Table.Cell>
                          <Grid stackable columns={1}>
                            <TranslatableTextField
                              disabled={isViewMode}
                              icon="sticky note outline"
                              name="value"
                              errorMessage={propertyErrors?.[property?.backendIndex]?.value}
                              label="Property Value"
                              value={property?.value}
                              onChange={(e, { value, name, language }) =>
                                this.onChangePropertyTranslatableText(
                                  e,
                                  {
                                    value,
                                    name,
                                    language,
                                  },
                                  propertyIndex,
                                )
                              }
                            />
                          </Grid>
                        </Table.Cell>
                        {isViewMode ? null : (
                          <Table.Cell verticalAlign="middle">
                            <Button icon color="red" onClick={() => this.onRemoveProperty(propertyIndex)}>
                              <Icon name="trash" />
                            </Button>
                          </Table.Cell>
                        )}
                      </Table.Row>
                    );
                  })}
                </Table.Body>
                {isViewMode ? null : (
                  <Table.Footer>
                    <Table.Row>
                      <Table.HeaderCell colSpan="5" textAlign="right">
                        <Button primary onClick={this.onAddProperty}>
                          {t('addProperty')}
                        </Button>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                )}
              </Table>
            </Segment>
          </Segment.Group>
        </Form.Field>
      </Grid.Column>
    );
  }
}

export default withTranslation(TRANSLATIONS.OFFERS)(OfferProperties);
