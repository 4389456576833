import React from 'react';
import { Tab, Segment, Grid, Button } from 'semantic-ui-react';
import { shape } from 'prop-types';
import { Loader } from '@shared/layout-components';
import { withTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import ROUTES from '@shared/consts/routes';
import { Link } from 'react-router-dom';
import { Message } from '@shared/components';
import { isEmpty } from 'lodash';
import OfferAttachments from './OfferAttachments';
import OfferMainContent from './OfferMainContent';
import OfferSections from './OfferSections';
import OfferAgreements from './OfferAgreements';
import './OfferForm.scss';

class OfferForm extends React.PureComponent {
  state = {
    offerFields: {
      id: this.props.offer.id,
      slug: this.props.offer.slug,
      title: this.props.offer.title,
      mainDescription: this.props.offer.mainDescription,
      projectType: this.props.offer.projectType,
      address: this.props.offer.address,
      isOpen: this.props.offer.isOpen,
      isVisible: this.props.offer.isVisible,
      totalCost: this.props.offer.totalCost,
      position: this.props.offer.position,
      investmentStart: this.props.offer.investmentStart,
      investmentEnd: this.props.offer.investmentEnd,
      imageUrl: this.props.offer.imageUrl,
      thumbUrl: this.props.offer.thumbUrl,
      investmentReturn: this.props.offer.investmentReturn,
      developerEquity: this.props.offer.developerEquity,
      fixedInvestorsEquity: this.props.offer.fixedInvestorsEquity,
      templateName: this.props.offer.templateName,
      series: this.props.offer.series,
    },
    offerAttachments: this.props.offer.investorAttachments,
    offerAgreements: this.props.offer.agreements,
    offerSections: this.props.offer.sections,
    offerBadges: this.props.offer.badges,
    offerProperties: this.props.offer.properties,
    offerDescriptions: this.props.offer.descriptions,
    offerImage: { delete: false },
    activeIndex: 0,
  };

  constructor(props) {
    super(props);
    this.setState = this.setState.bind(this);
    this.onSaveOffer = this.onSaveOffer.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.offer !== this.props.offer && !this.props.isCreateMode) {
      this.setState({
        offerFields: {
          id: this.props.offer.id,
          slug: this.props.offer.slug,
          title: this.props.offer.title,
          mainDescription: this.props.offer.mainDescription,
          projectType: this.props.offer.projectType,
          address: this.props.offer.address,
          isOpen: this.props.offer.isOpen,
          isVisible: this.props.offer.isVisible,
          totalCost: this.props.offer.totalCost,
          position: this.props.offer.position,
          minStock: this.props.offer.minStock,
          investmentStart: this.props.offer.investmentStart,
          investmentEnd: this.props.offer.investmentEnd,
          imageUrl: this.props.offer.imageUrl,
          thumbUrl: this.props.offer.thumbUrl,
          investmentReturn: this.props.offer.investmentReturn,
          developerEquity: this.props.offer.developerEquity,
          fixedInvestorsEquity: this.props.offer.fixedInvestorsEquity,
          templateName: this.props.offer.templateName,
          series: this.props.offer.series,
        },
        offerAttachments: this.props.offer.investorAttachments,
        offerAgreements: this.props.offer.agreements,
        offerSections: this.props.offer.sections,
        offerBadges: this.props.offer.badges,
        offerProperties: this.props.offer.properties,
        offerDescriptions: this.props.offer.descriptions,
        offerImage: { delete: false },
      });
    }
  }

  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });

  onSaveOffer({ saveAndBack }) {
    this.props.onSaveEditedOffer({
      saveAndBack,
      offerData: this.state.offerFields,
      offerProperties: this.state.offerProperties,
      offerBadges: this.state.offerBadges,
      offerDescriptions: this.state.offerDescriptions,
      offerImage: this.state.offerImage,
      offerId: this.state.offerFields.id,
      offerSections: this.state.offerSections,
      offerAttachments: this.state.offerAttachments,
      offerAgreements: this.state.offerAgreements,
    });
  }

  onCreateOffer({ saveAndBack }) {
    this.props.onCreateOffer({
      saveAndBack,
      offerData: this.state.offerFields,
      offerProperties: this.state.offerProperties,
      offerBadges: this.state.offerBadges,
      offerDescriptions: this.state.offerDescriptions,
      offerImage: this.state.offerImage,
      offerId: this.state.offerFields.id,
      offerSections: this.state.offerSections,
      offerAttachments: this.state.offerAttachments,
      offerAgreements: this.state.offerAgreements,
    });
  }

  disableButton() {
    return (
      (this.state.activeIndex === 1 &&
        this.state?.offerSections?.some?.(section => section?.elements?.some?.(element => !element?.type))) ||
      isEmpty(this.state.offerFields?.slug?.pl) ||
      isEmpty(this.state.offerFields?.title?.pl) ||
      this.props.isLoading
    );
  }

  renderMenuButtons() {
    const { t, isViewMode } = this.props;

    return (
      <Grid columns={3} >
        <Grid.Column>
          <h2>{t('mainTitleForm')}</h2>
        </Grid.Column>
        <Grid.Column className="d-flex align-items-center justify-content-center">
          {this?.props?.offerErrors?.default?.map?.(code => {
            return <Message danger>{t(`ERRORS:${code}`)}</Message>;
          })}
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Button as={Link} type="button" to={ROUTES.OFFERS}>
            {t('actionButtons.goBack')}
          </Button>
          {!this.props.isCreateMode && (
            <Button
              disabled={this.props.isLoading}
              type="submit"
              secondary
              onClick={() => this.props.onRefresh()}
              style={{ marginRight: '5px' }}
            >
              {t('reload')}
            </Button>
          )}
          {!this.props.isCreateMode && !isViewMode && (
            <Button
              disabled={this.disableButton()}
              variant="outlined"
              primary
              onClick={() => this.onSaveOffer({ saveAndBack: false })}
            >
              {t('save')}
            </Button>
          )}
          {!this.props.isCreateMode && !isViewMode && (
            <Button
              disabled={this.disableButton()}
              variant="outlined"
              color="facebook"
              onClick={() => this.onSaveOffer({ saveAndBack: true })}
            >
              {t('saveAndBack')}
            </Button>
          )}
          {this.props.isCreateMode && (
            <Button
              disabled={this.disableButton()}
              variant="outlined"
              primary
              onClick={() => this.onCreateOffer({ saveAndBack: false })}
            >
              {t('create')}
            </Button>
          )}
          {this.props.isCreateMode && (
            <Button
              disabled={this.disableButton()}
              variant="outlined"
              color="facebook"
              onClick={() => this.onCreateOffer({ saveAndBack: true })}
            >
              {t('createAndBack')}
            </Button>
          )}
        </Grid.Column>
      </Grid>
    );
  }

  render() {
    const { t, isViewMode } = this.props;

    const tabs = [
      {
        menuItem: t('mainContent'),
        pane: (
          <Tab.Pane key="main-content">
            <OfferMainContent
              isLoading={this.props.isLoading}
              offerFields={this.state.offerFields}
              offerBadges={this.state.offerBadges}
              offerProperties={this.state.offerProperties}
              offerDescriptions={this.state.offerDescriptions}
              offerImage={this.state.offerImage}
              onChange={this.setState}
              offerErrors={this.props.offerErrors}
              seriesOptions={this.props.seriesOptions}
              isViewMode={isViewMode}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: t('sections'),
        pane: (
          <Tab.Pane key="offers">
            <OfferSections
              isLoading={this.props.isLoading}
              offerSections={this.state.offerSections}
              onChange={this.setState}
              offerErrors={this.props.offerErrors}
              isViewMode={isViewMode}
            />
          </Tab.Pane>
        ),
      },
    ];

    return (
      <div className="offer-editor-form">
        <Segment raised className="card-header border-0 pt-5 sticky-menu-buttons">
          {this.renderMenuButtons()}
          {this.props.isLoading && <Loader />}
        </Segment>
        <Tab
          onTabChange={this.handleTabChange}
          activeIndex={this.state.activeIndex}
          renderActiveOnly={false}
          menu={{ attached: 'top' }}
          panes={tabs}
        />
        {!this.props.isLoading && (
          <Segment raised className="card-header border-0 pt-5">
            {this.renderMenuButtons()}
          </Segment>
        )}
      </div>
    );
  }
}

OfferForm.propTypes = {
  offer: shape({}).isRequired,
};

export default withTranslation([TRANSLATIONS.OFFERS, TRANSLATIONS.ERRORS])(OfferForm);
