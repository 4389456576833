import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import objectPath from 'object-path';
import { useHtmlClassService } from '@metronic/providers';
import { PrzySpacerowejSvgLogo } from '@shared/components';
import { func } from 'prop-types';

const MobileHeader = () => {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      headerLogo: uiService.getStickyLogo(),
      asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
      headerMenuSelfDisplay: objectPath.get(uiService.config, 'header.menu.self.display') === true,
      headerMobileCssClasses: uiService.getClasses('header_mobile', true),
      headerMobileAttributes: uiService.getAttributes('header_mobile'),
    };
  }, [uiService]);

  return (
    <>
      <div
        id="kt_header_mobile"
        className={`header-mobile align-items-center ${layoutProps.headerMobileCssClasses}`}
        {...layoutProps.headerMobileAttributes}
      >
        <Link to="/">
          <PrzySpacerowejSvgLogo isColored={false} />
        </Link>
        <div className="d-flex align-items-center">
          {layoutProps.asideDisplay && (
            <button type="button" className="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
              <span />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

MobileHeader.propTypes = {};

export default MobileHeader;
