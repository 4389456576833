import React from 'react';
import { Form, Grid, Segment, Label, Button, Icon } from 'semantic-ui-react';
import { TranslatableTextField, TranslatableRichTextField, SwitchField } from '../../../../../components';
import TimelineItemModel from '../../../../models/elementModels/TimelineElementModel/TimelineItemModel';
import { withTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { VALID_IMAGE_MIME_TYPES } from '@screens/page/const';
import { validateDocumentType, validateFileSize, validateImageType } from '@screens/page/utils';

class TimelineElement extends React.PureComponent {
  state = {
    imageData: {},
    imageUploadError: '',
  };

  constructor(props) {
    super(props);

    this.onChangeTranslatableContent = this.onChangeTranslatableContent.bind(this);
    this.onChangeCurrentFlag = this.onChangeCurrentFlag.bind(this);
    this.onRemoveTimelineStage = this.onRemoveTimelineStage.bind(this);
    this.onArrowUp = this.onArrowUp.bind(this);
    this.onArrowDown = this.onArrowDown.bind(this);
  }

  onChangeTranslatableContent(e, { name, value, language, innerElementPosition }) {
    const { onChange, sectionNumber, element } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      elements: el.elements.reduce((innerElementsAcc, innerElement) => {
                        if (innerElement.position === innerElementPosition) {
                          return innerElementsAcc.concat([
                            {
                              ...innerElement,
                              [name]: {
                                ...innerElement[name],
                                [language]: value,
                              },
                            },
                          ]);
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  onChangeCurrentFlag(e, { name, checked, innerElementPosition }) {
    const { onChange, sectionNumber, element } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      elements: el.elements.reduce((innerElementsAcc, innerElement) => {
                        if (innerElement.position === innerElementPosition) {
                          return innerElementsAcc.concat([
                            {
                              ...innerElement,
                              [name]: checked,
                            },
                          ]);
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  onRemoveTimelineStage(innerElementPosition) {
    const { onChange, sectionNumber, element } = this.props;

    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Are you sure you want to remove timeline stage? Timeline element changes will be discarded.`)) {
      onChange(prevState => ({
        offerSections: prevState.offerSections.reduce((acc, section) => {
          if (section.position === sectionNumber) {
            return acc.concat([
              {
                ...section,
                elements: section.elements.reduce((elementsAcc, el) => {
                  if (element.position === el.position) {
                    return elementsAcc.concat([
                      {
                        ...el,
                        elements: el.elements.reduce((innerElementsAcc, innerElement) => {
                          if (innerElement.position === innerElementPosition) {
                            return innerElementsAcc;
                          }
                          if (innerElement.position > innerElementPosition) {
                            return innerElementsAcc.concat([{ ...innerElement, position: innerElement.position - 1 }]);
                          }
                          return innerElementsAcc.concat([innerElement]);
                        }, []),
                      },
                    ]);
                  }
                  return elementsAcc.concat([el]);
                }, []),
              },
            ]);
          }
          return acc.concat([section]);
        }, []),
      }));
    }
  }

  onAddTimelineStage() {
    const { onChange, sectionNumber, element } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      elements: el.elements.concat([
                        {
                          ...TimelineItemModel,
                          position: el?.elements?.[el?.elements?.length - 1]?.position + 1,
                          backendIndex: el?.elements?.length || 0,
                        },
                      ]),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  onArrowUp(innerElementPosition) {
    const { onChange, sectionNumber, element } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      elements: el.elements.reduce((innerElementsAcc, innerElement) => {
                        const prevItem = el.elements.find(item => item.position === innerElementPosition - 1);
                        const matchingItem = el.elements.find(item => item.position === innerElementPosition);

                        if (innerElement.position === prevItem.position) {
                          return innerElementsAcc.concat({ ...matchingItem, position: prevItem.position });
                        }
                        if (innerElement.position === matchingItem.position) {
                          return innerElementsAcc.concat({ ...prevItem, position: matchingItem.position });
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  onArrowDown(innerElementPosition) {
    const { onChange, sectionNumber, element } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      elements: el.elements.reduce((innerElementsAcc, innerElement) => {
                        const nextItem = el.elements.find(item => item.position === innerElementPosition + 1);
                        const matchingItem = el.elements.find(item => item.position === innerElementPosition);

                        if (innerElement.position === matchingItem.position) {
                          return innerElementsAcc.concat({ ...nextItem, position: matchingItem.position });
                        }
                        if (innerElement.position === nextItem.position) {
                          return innerElementsAcc.concat({ ...matchingItem, position: nextItem.position });
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  onUploadImage = (e, innerElementPosition) => {
    const { onChange, sectionNumber, element } = this.props;

    const reader = new FileReader();
    const file = e.target.files[0];

    if (file) {
      reader.onloadend = () => {
        return onChange(prevState => ({
          offerSections: prevState.offerSections.reduce((acc, section) => {
            if (section.position === sectionNumber) {
              return acc.concat([
                {
                  ...section,
                  elements: section.elements.reduce((elementsAcc, el) => {
                    if (element.position === el.position) {
                      return elementsAcc.concat([
                        {
                          ...el,
                          elements: el.elements.reduce((innerElementsAcc, innerElement) => {
                            if (innerElement.position === innerElementPosition) {
                              return innerElementsAcc.concat([
                                {
                                  ...innerElement,
                                  image: {
                                    fileName: file.name,
                                    delete: false,
                                    base64: reader.result,
                                  },
                                },
                              ]);
                            }
                            return innerElementsAcc.concat([innerElement]);
                          }, []),
                        },
                      ]);
                    }
                    return elementsAcc.concat([el]);
                  }, []),
                },
              ]);
            }
            return acc.concat([section]);
          }, []),
        }));
      };

      return reader.readAsDataURL(file);
    }
  };

  removeImage = (e, innerElementPosition) => {
    const { sectionNumber, element, onChange } = this.props;
    e.preventDefault();

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      elements: el.elements.reduce((innerElementsAcc, innerElement) => {
                        if (innerElement.position === innerElementPosition) {
                          return innerElementsAcc.concat([
                            {
                              ...innerElement,
                              image: { base64: undefined, delete: true, name: undefined },
                            },
                          ]);
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  };

  render() {
    const { element, elementErrors, t, isViewMode } = this.props;

    return (
      <>
        {isViewMode ? null : (
          <Form.Group>
            <Grid container>
              <Grid.Column>
                <Button onClick={() => this.onAddTimelineStage()}>
                  <Icon name="plus" />
                  {t('timeline.newItem')}
                </Button>
              </Grid.Column>
            </Grid>
          </Form.Group>
        )}
        {element.elements.map(innerElement => (
          <Segment key={innerElement.position}>
            <Form.Group>
              <Grid container stackable columns={1} divided>
                <Grid.Row>
                  <Label>{innerElement.position}. Timeline Stage</Label>

                  {isViewMode ? null : (
                    <>
                      <Button
                        icon
                        className="mr-2 ml-2"
                        disabled={innerElement.position === element.elements[0].position}
                        onClick={() => this.onArrowUp(innerElement.position)}
                      >
                        <Icon name="arrow up" />
                      </Button>
                      <Button
                        icon
                        className="mr-2 ml-2"
                        disabled={innerElement.position === element.elements[element.elements.length - 1].position}
                        onClick={() => this.onArrowDown(innerElement.position)}
                      >
                        <Icon name="arrow down" />
                      </Button>
                      <Button
                        className="mr-2 ml-2"
                        icon
                        color="red"
                        onClick={() => this.onRemoveTimelineStage(innerElement.position)}
                      >
                        <Icon name="trash" />
                      </Button>
                    </>
                  )}
                </Grid.Row>
                <SwitchField
                  disabled={isViewMode}
                  name="current"
                  label={t('timeline.currentStage')}
                  value={innerElement?.current}
                  onChange={(e, { name, checked }) =>
                    this.onChangeCurrentFlag(e, {
                      name,
                      checked,
                      innerElementPosition: innerElement.position,
                    })
                  }
                />
              </Grid>
            </Form.Group>
            <Form.Group>
              <Grid container stackable columns={1} divided>
                <TranslatableTextField
                  disabled={isViewMode}
                  icon="font"
                  label={t('timeline.label')}
                  name="label"
                  value={innerElement.label}
                  errorMessage={elementErrors?.elements?.[innerElement?.backendIndex]?.label}
                  onChange={(e, { name, language, value }) =>
                    this.onChangeTranslatableContent(e, {
                      name,
                      language,
                      value,
                      innerElementPosition: innerElement.position,
                    })
                  }
                />
              </Grid>
            </Form.Group>
            <Form.Group>
              <Grid container stackable columns={1} divided>
                <TranslatableTextField
                  icon="calendar"
                  label={t('timeline.date')}
                  name="date"
                  disabled={isViewMode}
                  value={innerElement.date}
                  errorMessage={elementErrors?.elements?.[innerElement?.backendIndex]?.date}
                  onChange={(e, { name, language, value }) =>
                    this.onChangeTranslatableContent(e, {
                      name,
                      language,
                      value,
                      innerElementPosition: innerElement.position,
                    })
                  }
                />
              </Grid>
            </Form.Group>
            <Form.Group>
              <Grid container stackable columns={1} divided>
                <TranslatableRichTextField
                  disabled={isViewMode}
                  label={t('timeline.content')}
                  name="content"
                  errorMessage={elementErrors?.elements?.[innerElement?.backendIndex]?.content}
                  value={innerElement.content}
                  onChange={(e, { name, language, value }) =>
                    this.onChangeTranslatableContent(e, {
                      name,
                      language,
                      value,
                      innerElementPosition: innerElement.position,
                    })
                  }
                />
              </Grid>
            </Form.Group>
            <Form.Group>
              <Grid container stackable columns={2} divided>
                <Grid.Column>
                  <div className="{styles['page-image-upload--buttons']}">
                    <div className="page-errors">
                      {this.state.imageUploadError && (
                        <p style={{ color: 'red' }} key={this.state.imageUploadError}>
                          {this.state.imageUploadError}
                        </p>
                      )}
                    </div>
                    <Form.Field>
                      <label aria-controls="" htmlFor="create-page-add-file">
                        <p>{t('imageUpload')}</p>
                        <input
                          id="create-page-add-file"
                          type="file"
                          onChange={e => this.onUploadImage(e, innerElement.position)}
                          accept={VALID_IMAGE_MIME_TYPES.join(', ')}
                        />
                      </label>
                    </Form.Field>
                    <br />

                    {element?.elements?.[innerElement.backendIndex]?.image?.base64 ||
                    element?.elements?.[innerElement.backendIndex]?.imageUrl ? (
                      <Button
                        variant="outlined"
                        type="button"
                        onClick={e => this.removeImage(e, innerElement.position)}
                      >
                        {t('removePhoto')}
                      </Button>
                    ) : null}
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field>
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label>{t('imagePreview')}</label>
                    {!element?.elements?.[innerElement.backendIndex]?.image?.delete && (
                      <img
                        style={{ maxWidth: '100%', borderRadius: 4 }}
                        src={
                          element?.elements?.[innerElement.backendIndex]?.image?.base64 ||
                          element?.elements?.[innerElement.backendIndex]?.imageUrl
                        }
                        alt={t('imagePreview')}
                      />
                    )}
                  </Form.Field>
                </Grid.Column>
              </Grid>
            </Form.Group>
          </Segment>
        ))}
      </>
    );
  }
}

export default withTranslation(TRANSLATIONS.OFFERS)(TimelineElement);
