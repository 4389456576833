import {
  EDIT_OFFER_INIT,
  EDIT_OFFER_SUCCESS,
  EDIT_OFFER_FAILURE,
  GET_SINGLE_OFFER_SUCCESS,
} from '@store/action-types/offers';
import client from '@api-sdk/client';
import moment from 'moment';
import { toPath, set, isEmpty } from 'lodash';
import translateToastMessage from '@shared/utils/translateToastMessage';
import { enqueueSnackbar } from '@store/actions/toast';
import history from '@store/history';
import ROUTES from '@shared/consts/routes';
import sectionMapper from '../utils/sectionMapper';
import mapSingleOfferResponse from '../utils/responseMapper';

const editOfferInit = () => ({ type: EDIT_OFFER_INIT });
const editOfferSuccess = singleOffer => ({
  type: EDIT_OFFER_SUCCESS,
  payload: { singleOffer },
});
const editOfferFailure = error => {
  const mappedErrors = error?.errors?.reduce?.((acc, item) => {
    const isSlugNotUnique = item.code === 'OFFER:SLUG_NOT_UNIQUE';
    const path = toPath(item.context).filter(
      item => item !== 'children' && item !== 'data' && item !== 'pl' && item !== 'en',
    );

    const errorsWithoutContext = [];

    if (isSlugNotUnique) {
      set(acc, 'slug', item.code?.split?.(':')?.pop() || item.message);
    }

    if (!item.context && !isSlugNotUnique) {
      errorsWithoutContext.push(item.code?.split?.(':', item.code.length)?.pop?.());
    }

    set(acc, path, item.code?.split?.(':')?.pop() || item.message);

    acc.default = errorsWithoutContext;

    return acc;
  }, {});

  return { type: EDIT_OFFER_FAILURE, payload: { error: { ...error, errors: mappedErrors } } };
};

const getSingleOfferSuccess = singleOffer => ({
  type: GET_SINGLE_OFFER_SUCCESS,
  payload: { singleOffer },
});

const editOffer = ({
  saveAndBack = false,
  offerData,
  offerProperties,
  offerBadges,
  offerDescriptions,
  offerImage,
  offerId,
  offerSections,
  offerAttachments,
  offerAgreements,
}) => async dispatch => {
  dispatch(editOfferInit());

  try {
    const editOfferResponse = await client.editOffer({
      data: {
        address: offerData?.address,
        investmentReturn: offerData?.investmentReturn,
        investmentEnd: offerData?.investmentEnd ? moment(offerData?.investmentEnd).format('YYYY-MM-DD') : '',
        investmentStart: offerData?.investmentStart ? moment(offerData?.investmentStart).format('YYYY-MM-DD') : '',
        isOpen: offerData?.isOpen,
        isVisible: offerData?.isVisible,
        mainDescription: offerData?.mainDescription,
        projectType: offerData?.projectType,
        slug: offerData?.slug,
        templateName: offerData?.templateName,
        title: offerData?.title,
        series: offerData?.series,
        totalCost: offerData?.totalCost,
        position: offerData?.position,
        fixedInvestorsEquity: offerData?.shouldEnableFixedInvestorsEquity ? offerData?.fixedInvestorsEquity : null,
        properties: offerProperties?.reduce?.((acc, property) => {
          acc[property.backendIndex] = { ...property, backendIndex: undefined };

          return acc;
        }, {}),
        badges: offerBadges?.reduce?.((acc, badge) => {
          acc[badge.backendIndex] = { ...badge, backendIndex: undefined };

          return acc;
        }, {}),
        descriptions: offerDescriptions?.reduce?.((acc, description) => {
          acc[description.backendIndex] = { ...description, backendIndex: undefined };

          return acc;
        }, {}),
        investorAttachments: offerAttachments?.reduce?.((acc, attachment) => {
          if (attachment?.file?.delete) {
            return acc;
          }

          acc[attachment.backendIndex] = {
            displayName: attachment.displayName,
            ...(!isEmpty(attachment?.file) && { file: { ...attachment.file } }),
            backendIndex: undefined,
            position: undefined,
          };

          return acc;
        }, {}),
        image: offerImage,
        sections: sectionMapper(offerSections),
        agreements: offerAgreements?.reduce?.((acc, agreement) => {
          if (agreement?.toBeDeleted) {
            return acc;
          }

          acc[agreement.backendIndex] = {
            ...agreement,
            backendIndex: undefined,
            id: undefined,
            initWithEditor: undefined,
            toBeDeleted: undefined,
            position: Number(agreement.position),
          };

          return acc;
        }, {}),
      },
      slug: offerId,
    });

    if (saveAndBack) {
      history.push(ROUTES.OFFERS);
    }

    await dispatch(editOfferSuccess(editOfferResponse));
    await dispatch(
      getSingleOfferSuccess({ ...editOfferResponse, response: mapSingleOfferResponse(editOfferResponse.response) }),
    );
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('offer.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('offer.error.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    dispatch(editOfferFailure(error));
  }
};

export default editOffer;
