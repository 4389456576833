import React from 'react';
import {
  Form,
  Grid,
  Label,
  Segment,
  Dropdown,
  Menu,
  Container,
  Button,
  Icon,
  Header,
  Divider,
} from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { ELEMENT_TYPES, getElementTypesDropdownOptions } from './ElementTypes';
import {
  GalleryElement,
  AttachmentsElement,
  BarChartElement,
  PieChartElement,
  CounterElement,
  ImageElement,
  MapElement,
  TextElement,
  TimelineElement,
  YoutubeElement,
  TableElement,
} from './components';
import { getSizeTypesDropdownOptions } from './SizeTypes';
import OfferSectionElementModel from '../../models/OfferSectionElementModel';
import { TranslatableTextField } from '../../../components';

class OfferSectionElement extends React.PureComponent {
  state = {
    isSectionElementExpanded: true,
  };

  constructor(props) {
    super(props);

    this.onRemoveSectionElement = this.onRemoveSectionElement.bind(this);
    this.onChangeElementPositionArrowDown = this.onChangeElementPositionArrowDown.bind(this);
    this.onChangeElementPositionArrowUp = this.onChangeElementPositionArrowUp.bind(this);
    this.onChangeElementWidth = this.onChangeElementWidth.bind(this);
  }

  toggleIsExpanded = () =>
    this.setState(prevState => ({ ...prevState, isSectionElementExpanded: !prevState.isSectionElementExpanded }));

  onRemoveSectionElement(elementPosition) {
    const { onChange, sectionNumber } = this.props;

    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        `Are you sure you want to remove element ${sectionNumber}.${elementPosition}? Element changes will be discarded.`,
      )
    ) {
      onChange(prevState => ({
        offerSections: prevState.offerSections.reduce((acc, section) => {
          if (section.position === sectionNumber) {
            return acc.concat([
              {
                ...section,
                elements: section.elements.reduce((elementsAcc, element) => {
                  if (element.position === elementPosition) {
                    return elementsAcc;
                  }
                  if (element.position > elementPosition) {
                    return elementsAcc.concat({ ...element, position: element.position - 1 });
                  }
                  return elementsAcc.concat(element);
                }, []),
              },
            ]);
          }
          return acc.concat([section]);
        }, []),
      }));
    }
  }

  onChangeElementPositionArrowDown(elementPosition) {
    const { onChange, sectionNumber } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, element) => {
                const nextItem = section.elements.find(item => item.position === elementPosition + 1);
                const matchingItem = section.elements.find(item => item.position === elementPosition);

                if (element.position === matchingItem.position) {
                  return elementsAcc.concat({ ...nextItem, position: matchingItem.position });
                }
                if (element.position === nextItem.position) {
                  return elementsAcc.concat({ ...matchingItem, position: nextItem.position });
                }
                return elementsAcc.concat(element);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  onChangeElementPositionArrowUp(elementPosition) {
    const { onChange, sectionNumber } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, element) => {
                const prevItem = section.elements.find(item => item.position === elementPosition - 1);
                const matchingItem = section.elements.find(item => item.position === elementPosition);

                if (element.position === prevItem.position) {
                  return elementsAcc.concat({ ...matchingItem, position: prevItem.position });
                }
                if (element.position === matchingItem.position) {
                  return elementsAcc.concat({ ...prevItem, position: matchingItem.position });
                }
                return elementsAcc.concat(element);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  onChangeElementWidth({ elementPosition, value, name }) {
    const { onChange, sectionNumber } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, element) => {
                if (element.position === elementPosition) {
                  return elementsAcc.concat([{ ...element, grid: { ...element.grid, [name]: value } }]);
                }
                return elementsAcc.concat([element]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  // TODO: watch out with that backendIndex + 1 here
  onChangeElementType({ elementPosition, value }) {
    const { onChange, sectionNumber } = this.props;

    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        `Are you sure you want to change element ${sectionNumber}.${elementPosition} type? Element changes will be discarded.`,
      )
    ) {
      onChange(prevState => ({
        offerSections: prevState.offerSections.reduce((acc, section) => {
          if (section.position === sectionNumber) {
            return acc.concat([
              {
                ...section,
                elements: section.elements.reduce((elementsAcc, element) => {
                  if (element.position === elementPosition) {
                    return elementsAcc.concat([
                      {
                        ...OfferSectionElementModel,
                        position: element.position,
                        type: value,
                        ...ELEMENT_TYPES[value].additionalFields,
                        backendIndex: element.backendIndex + 1 || section.elements.length,
                      },
                    ]);
                  }
                  return elementsAcc.concat([element]);
                }, []),
              },
            ]);
          }
          return acc.concat([section]);
        }, []),
      }));
    }
  }

  onCheckboxChange = ({ elementPosition, name }) => {
    const { onChange, sectionNumber } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, element) => {
                if (element.position === elementPosition) {
                  return elementsAcc.concat([
                    {
                      ...element,
                      [name]: !element[name],
                    },
                  ]);
                }
                return elementsAcc.concat([element]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  };

  onChangeElementTitle({ elementPosition, value, language }) {
    const { onChange, sectionNumber } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, element) => {
                if (element.position === elementPosition) {
                  return elementsAcc.concat([
                    {
                      ...element,
                      title: {
                        ...element.title,
                        [language]: value,
                      },
                    },
                  ]);
                }
                return elementsAcc.concat([element]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  renderElementTypeComponent() {
    const { element, onChange, sectionNumber, elementErrors, isViewMode } = this.props;

    switch (element.type) {
      case ELEMENT_TYPES.gallery.value: {
        return (
          <GalleryElement
            element={element}
            elementErrors={elementErrors}
            onChange={onChange}
            sectionNumber={sectionNumber}
            isViewMode={isViewMode}
          />
        );
      }
      case ELEMENT_TYPES.attachments.value: {
        return (
          <AttachmentsElement
            element={element}
            elementErrors={elementErrors}
            onChange={onChange}
            sectionNumber={sectionNumber}
            isViewMode={isViewMode}
          />
        );
      }
      case ELEMENT_TYPES.barChart.value: {
        return (
          <BarChartElement
            element={element}
            elementErrors={elementErrors}
            onChange={onChange}
            sectionNumber={sectionNumber}
            isViewMode={isViewMode}
          />
        );
      }
      case ELEMENT_TYPES.pieChart.value: {
        return (
          <PieChartElement
            element={element}
            elementErrors={elementErrors}
            onChange={onChange}
            sectionNumber={sectionNumber}
            isViewMode={isViewMode}
          />
        );
      }
      case ELEMENT_TYPES.counter.value: {
        return (
          <CounterElement
            element={element}
            elementErrors={elementErrors}
            onChange={onChange}
            sectionNumber={sectionNumber}
            isViewMode={isViewMode}
          />
        );
      }
      case ELEMENT_TYPES.image.value: {
        return (
          <ImageElement
            element={element}
            elementErrors={elementErrors}
            onChange={onChange}
            sectionNumber={sectionNumber}
            isViewMode={isViewMode}
          />
        );
      }
      case ELEMENT_TYPES.map.value: {
        return (
          <MapElement
            element={element}
            elementErrors={elementErrors}
            onChange={onChange}
            sectionNumber={sectionNumber}
            isViewMode={isViewMode}
          />
        );
      }
      case ELEMENT_TYPES.text.value: {
        return (
          <TextElement
            element={element}
            elementErrors={elementErrors}
            onChange={onChange}
            sectionNumber={sectionNumber}
            isViewMode={isViewMode}
          />
        );
      }
      case ELEMENT_TYPES.timeline.value: {
        return (
          <TimelineElement
            element={element}
            elementErrors={elementErrors}
            onChange={onChange}
            sectionNumber={sectionNumber}
            isViewMode={isViewMode}
          />
        );
      }
      case ELEMENT_TYPES.youtube.value: {
        return (
          <YoutubeElement
            element={element}
            elementErrors={elementErrors}
            onChange={onChange}
            sectionNumber={sectionNumber}
            isViewMode={isViewMode}
          />
        );
      }
      case ELEMENT_TYPES.table.value: {
        return (
          <TableElement
            element={element}
            elementErrors={elementErrors}
            onChange={onChange}
            sectionNumber={sectionNumber}
            isViewMode={isViewMode}
          />
        );
      }
      default: {
        const { t } = this.props;
        return (
          <Segment placeholder>
            <Header icon>
              <Icon name="hand pointer outline" />
              {t('selectElementType')}
            </Header>
          </Segment>
        );
      }
    }
  }

  render() {
    const { element, sectionNumber, disableUpArrow, disableDownArrow, elementErrors, t, isViewMode } = this.props;

    // TODO: hackish way to convert Semantic UI 16-col grid to Material UI 12-col grid that we have on frontend, fix that
    return (
      <Grid.Column computer={Math.round(element.grid.desktop * 1.33)}>
        <Form>
          <Segment color="black" raised>
            <Menu fluid className={`offer-element ${element.grid.desktop < 12 ? 'stack-elements' : ''}`}>
              <Menu.Menu position="left">
                <Menu.Item>
                  <Label size="medium" color="black">
                    {t('element')} {sectionNumber}.{element.position}
                  </Label>
                </Menu.Item>
                <Menu.Item>
                  <Button color="green" icon onClick={() => this.toggleIsExpanded()}>
                    {this.state.isSectionElementExpanded ? (
                      <Icon name="angle double up" />
                    ) : (
                      <Icon name="angle double down" />
                    )}
                  </Button>
                </Menu.Item>
                <Menu.Item>
                  <Dropdown
                    disabled={isViewMode}
                    placeholder={t('selectElementType')}
                    selection
                    button
                    value={element.type}
                    onChange={(e, { value }) => this.onChangeElementType({ elementPosition: element.position, value })}
                    options={getElementTypesDropdownOptions(t)}
                  />
                </Menu.Item>
                {isViewMode ? null : (
                  <Menu.Item>
                    <Button color="red" icon onClick={() => this.onRemoveSectionElement(element.position)}>
                      <Icon name="trash" />
                    </Button>
                  </Menu.Item>
                )}
                {isViewMode ? null : (
                  <Menu.Item>
                    <Button
                      icon
                      className="mr-2 ml-2"
                      onClick={() => this.onChangeElementPositionArrowUp(element.position)}
                      disabled={disableUpArrow}
                    >
                      <Icon name="arrow up" />
                    </Button>
                    <Button
                      icon
                      className="mr-2 ml-2"
                      onClick={() => this.onChangeElementPositionArrowDown(element.position)}
                      disabled={disableDownArrow}
                    >
                      <Icon name="arrow down" />
                    </Button>
                  </Menu.Item>
                )}
              </Menu.Menu>
              <Menu.Menu position="right">
                <Menu.Item>
                  <Dropdown
                    icon="desktop"
                    className="icon"
                    disabled={isViewMode}
                    labeled
                    fluid
                    button
                    selection
                    placeholder={t('desktop')}
                    label={t('desktop')}
                    value={element?.grid?.desktop}
                    name="desktop"
                    options={getSizeTypesDropdownOptions()}
                    onChange={(e, { value, name }) =>
                      this.onChangeElementWidth({
                        elementPosition: element.position,
                        value,
                        name,
                      })
                    }
                  />
                </Menu.Item>
                <Menu.Item>
                  <Dropdown
                    disabled={isViewMode}
                    icon="tablet alternate"
                    className="icon"
                    labeled
                    fluid
                    button
                    selection
                    placeholder={t('tablet')}
                    label={t('tablet')}
                    value={element?.grid?.tablet}
                    name="tablet"
                    options={getSizeTypesDropdownOptions()}
                    onChange={(e, { value, name }) =>
                      this.onChangeElementWidth({
                        elementPosition: element.position,
                        value,
                        name,
                      })
                    }
                  />
                </Menu.Item>
                <Menu.Item>
                  <Dropdown
                    disabled={isViewMode}
                    icon="mobile"
                    className="icon"
                    labeled
                    fluid
                    button
                    selection
                    placeholder={t('mobile')}
                    label={t('mobile')}
                    name="mobile"
                    value={element?.grid?.mobile}
                    options={getSizeTypesDropdownOptions()}
                    onChange={(e, { value, name }) =>
                      this.onChangeElementWidth({
                        elementPosition: element.position,
                        value,
                        name,
                      })
                    }
                  />
                </Menu.Item>
              </Menu.Menu>
            </Menu>
            {this.state.isSectionElementExpanded && (
              <>
                <Container fluid>
                  <Form.Group>
                    <Grid container stackable columns={2} divided>
                      <TranslatableTextField
                        disabled={isViewMode}
                        name="title"
                        label={t('elementTitle')}
                        errorMessage={elementErrors?.title}
                        onChange={(e, { value, name, language }) =>
                          this.onChangeElementTitle({
                            elementPosition: element.position,
                            value,
                            name,
                            language,
                          })
                        }
                        value={element.title}
                        icon="font"
                      />
                    </Grid>
                  </Form.Group>
                  <Divider />
                </Container>
                <Container fluid>{this.renderElementTypeComponent()}</Container>
              </>
            )}
          </Segment>
        </Form>
      </Grid.Column>
    );
  }
}

export default withTranslation(TRANSLATIONS.OFFERS)(OfferSectionElement);
