import { ELEMENT_TYPES } from '@screens/offers/NewOfferForm/OfferForm/OfferSections/elements';

const mapInnerElementPropertiesToType = (innerElement, elementType) => {
  switch (elementType) {
    case ELEMENT_TYPES.gallery.value: {
      return {
        description: innerElement.description,
        title: innerElement.title,
        position: innerElement.position,
        file: { ...innerElement.file },
      };
    }
    case ELEMENT_TYPES.attachments.value: {
      return {
        position: innerElement.position,
        attachment: {
          ...innerElement.attachment,
          id: undefined,
          url: undefined,
        },
      };
    }
    case ELEMENT_TYPES.timeline.value: {
      return {
        ...innerElement,
        isCurrent: innerElement.current,
        current: undefined,
        backendIndex: undefined,
        imageUrl: undefined,
        thumbUrl: undefined,
      };
    }

    default: {
      return { ...innerElement, backendIndex: undefined };
    }
  }
};

const mapInnerElements = (innerElements, elementType) => {
  return innerElements.reduce((mappedInnerElements, innerElement) => {
    if (innerElement?.file?.delete || innerElement?.attachment?.file?.delete) {
      return mappedInnerElements;
    }

    mappedInnerElements[innerElement.backendIndex] = { ...mapInnerElementPropertiesToType(innerElement, elementType) };

    return mappedInnerElements;
  }, {});
};

const mapSectionElements = sectionElements => {
  return sectionElements?.reduce((mappedSectionElements, sectionElement) => {
    mappedSectionElements[sectionElement.backendIndex] = {
      ...sectionElement,
      ...(sectionElement.elements && { elements: mapInnerElements(sectionElement.elements, sectionElement.type) }),
      backendIndex: undefined,
      thumbUrl: undefined,
      imageUrl: undefined,
    };

    return mappedSectionElements;
  }, {});
};

const mapSectionsToSave = sections => {
  const result = sections?.reduce?.((mappedSections, section) => {
    mappedSections[section.backendIndex] = {
      ...section,
      elements: mapSectionElements(section?.elements),
      backendIndex: undefined,
    };

    return mappedSections;
  }, {});

  return result;
};

export default mapSectionsToSave;
