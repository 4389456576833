import React from 'react';
import { Segment, Label, Image, Grid, Button, Icon, Input, Form } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { TranslatableTextField } from '../../../../../components';
import VALID_IMAGE_MIME_TYPES from '../../../../models/ImageTypes';
import GalleryItemModel from '../../../../models/elementModels/GalleryElementModel/GalleryItemModel';

class GalleryElement extends React.PureComponent {
  elementImageRef = React.createRef();

  constructor(props) {
    super(props);

    this.onChangeTranslatableContent = this.onChangeTranslatableContent.bind(this);
    this.onUploadImage = this.onUploadImage.bind(this);
    this.onToggleRemoveGalleryItem = this.onToggleRemoveGalleryItem.bind(this);
    this.onArrowUp = this.onArrowUp.bind(this);
    this.onArrowDown = this.onArrowDown.bind(this);
    this.onAddGalleryItem = this.onAddGalleryItem.bind(this);
  }

  componentDidMount() {
    const { onChange, sectionNumber, element } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      elements: el?.elements
                        ?.sort((a, b) => a.position - b.position)
                        ?.map?.((elm, index) => ({ ...elm, position: index + 1 })),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  onChangeTranslatableContent(e, { name, value, language, innerElementPosition }) {
    const { onChange, sectionNumber, element } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      elements: el.elements.reduce((innerElementsAcc, innerElement) => {
                        if (innerElement.position === innerElementPosition) {
                          return innerElementsAcc.concat([
                            {
                              ...innerElement,
                              [name]: {
                                ...innerElement[name],
                                [language]: value,
                              },
                            },
                          ]);
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  onRemoveGalleryItem(innerElementPosition) {
    const { onChange, sectionNumber, element } = this.props;

    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Are you sure you want to remove image #${innerElementPosition}? Image will be discarded.`)) {
      onChange(prevState => ({
        offerSections: prevState.offerSections.reduce((acc, section) => {
          if (section.position === sectionNumber) {
            return acc.concat([
              {
                ...section,
                elements: section.elements.reduce((elementsAcc, el) => {
                  if (element.position === el.position) {
                    return elementsAcc.concat([
                      {
                        ...el,
                        elements: el.elements.reduce((innerElementsAcc, innerElement) => {
                          if (innerElement.position === innerElementPosition) {
                            return innerElementsAcc;
                          }
                          if (innerElement.position > innerElementPosition) {
                            return innerElementsAcc.concat([{ ...innerElement, position: innerElement.position - 1 }]);
                          }
                          return innerElementsAcc.concat([innerElement]);
                        }, []),
                      },
                    ]);
                  }
                  return elementsAcc.concat([el]);
                }, []),
              },
            ]);
          }
          return acc.concat([section]);
        }, []),
      }));
    }
  }

  onToggleRemoveGalleryItem(innerElementPosition, toBeRemoved) {
    const { onChange, sectionNumber, element } = this.props;

    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Are you sure you want to remove image #${innerElementPosition}? Image will be discarded.`)) {
      onChange(prevState => ({
        offerSections: prevState.offerSections.reduce((acc, section) => {
          if (section.position === sectionNumber) {
            return acc.concat([
              {
                ...section,
                elements: section.elements.reduce((elementsAcc, el) => {
                  if (element.position === el.position) {
                    return elementsAcc.concat([
                      {
                        ...el,
                        elements: el.elements.reduce((innerElementsAcc, innerElement) => {
                          if (innerElement.position === innerElementPosition) {
                            return innerElementsAcc.concat([
                              {
                                ...innerElement,
                                file: {
                                  ...innerElement.file,
                                  delete: toBeRemoved,
                                },
                              },
                            ]);
                          }
                          return innerElementsAcc.concat([innerElement]);
                        }, []),
                      },
                    ]);
                  }
                  return elementsAcc.concat([el]);
                }, []),
              },
            ]);
          }
          return acc.concat([section]);
        }, []),
      }));
    }
  }

  onUploadImage(e, innerElementPosition) {
    const { onChange, sectionNumber, element } = this.props;

    const reader = new FileReader();
    const file = e.target.files[0];

    if (file) {
      reader.onloadend = () => {
        return onChange(prevState => ({
          offerSections: prevState.offerSections.reduce((acc, section) => {
            if (section.position === sectionNumber) {
              return acc.concat([
                {
                  ...section,
                  elements: section.elements.reduce((elementsAcc, el) => {
                    if (element.position === el.position) {
                      return elementsAcc.concat([
                        {
                          ...el,
                          elements: el.elements.reduce((innerElementsAcc, innerElement) => {
                            if (innerElement.position === innerElementPosition) {
                              return innerElementsAcc.concat([
                                {
                                  ...innerElement,
                                  file: {
                                    base64: reader.result,
                                    fileName: file.name,
                                    delete: false,
                                  },
                                },
                              ]);
                            }
                            return innerElementsAcc.concat([innerElement]);
                          }, []),
                        },
                      ]);
                    }
                    return elementsAcc.concat([el]);
                  }, []),
                },
              ]);
            }
            return acc.concat([section]);
          }, []),
        }));
      };

      return reader.readAsDataURL(file);
    }
  }

  onArrowUp(innerElementPosition) {
    const { onChange, sectionNumber, element } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      elements: el.elements.reduce((innerElementsAcc, innerElement) => {
                        const prevItem = el.elements.find(item => item.position === innerElementPosition - 1);
                        const matchingItem = el.elements.find(item => item.position === innerElementPosition);

                        if (innerElement.position === prevItem.position) {
                          return innerElementsAcc.concat({ ...matchingItem, position: prevItem.position });
                        }
                        if (innerElement.position === matchingItem.position) {
                          return innerElementsAcc.concat({ ...prevItem, position: matchingItem.position });
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  onArrowDown(innerElementPosition) {
    const { onChange, sectionNumber, element } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      elements: el.elements.reduce((innerElementsAcc, innerElement) => {
                        const nextItem = el.elements.find(item => item.position === innerElementPosition + 1);
                        const matchingItem = el.elements.find(item => item.position === innerElementPosition);

                        if (innerElement.position === matchingItem.position) {
                          return innerElementsAcc.concat({ ...nextItem, position: matchingItem.position });
                        }
                        if (innerElement.position === nextItem.position) {
                          return innerElementsAcc.concat({ ...matchingItem, position: nextItem.position });
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  onAddGalleryItem() {
    const { onChange, sectionNumber, element } = this.props;

    const setBackendIndex = elements => Math.max(...elements.map(el => el.backendIndex), 0);

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      elements: [
                        ...el?.elements?.reduce((elAcc, e) => {
                          return elAcc?.concat({ ...e, position: e.position + 1, backendIndex: e.backendIndex });
                        }, []),
                        {
                          ...GalleryItemModel,
                          position: 1,
                          backendIndex: setBackendIndex(el?.elements) + 1 || 0,
                        },
                      ],
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  render() {
    const { element, elementErrors, t, isViewMode } = this.props;

    return (
      <div>
        {isViewMode ? null : (
          <Form.Group>
            <Grid container stackable columns={2} divided verticalAlign="middle">
              <Grid.Column>
                <Button onClick={() => this.onAddGalleryItem()}>
                  <Icon name="plus" />
                  {t('gallery.newItem')}
                </Button>
              </Grid.Column>
            </Grid>
          </Form.Group>
        )}

        {element?.elements
          ?.sort?.((a, b) => a.position - b.position)
          ?.map(innerElement => {
            return (
              <Segment key={innerElement.position}>
                <Grid verticalAlign="middle">
                  <Grid container>
                    <Grid.Row>
                      <Label ribbon>Image #{innerElement.position}</Label>
                      {isViewMode ? null : (
                        <>
                          <Button
                            icon
                            className="mr-2 ml-2"
                            disabled={innerElement.position === element.elements[0].position}
                            onClick={() => this.onArrowUp(innerElement.position)}
                          >
                            <Icon name="arrow up" />
                          </Button>
                          <Button
                            icon
                            className="mr-2 ml-2"
                            disabled={innerElement.position === element.elements[element.elements.length - 1].position}
                            onClick={() => this.onArrowDown(innerElement.position)}
                          >
                            <Icon name="arrow down" />
                          </Button>
                          {innerElement.imageUrl && (
                            <Button
                              icon
                              className="mr-2 ml-2"
                              color="red"
                              onClick={() => this.onToggleRemoveGalleryItem(innerElement.position, true)}
                            >
                              <Icon name="trash" />
                              {t('gallery.markToDelete')}
                            </Button>
                          )}
                          {!innerElement.imageUrl && (
                            <Button
                              className="mr-2 ml-2"
                              icon
                              color="red"
                              onClick={() => this.onRemoveGalleryItem(innerElement.position)}
                            >
                              <Icon name="trash" />
                              {t('delete')}
                            </Button>
                          )}
                          {innerElement?.file?.delete && (
                            <Button
                              icon
                              className="mr-2 ml-2"
                              color="blue"
                              onClick={() => this.onToggleRemoveGalleryItem(innerElement.position, false)}
                            >
                              <Icon name="undo" />
                              Undo deletion
                            </Button>
                          )}
                        </>
                      )}
                    </Grid.Row>
                  </Grid>
                </Grid>
                <Grid stackable container columns={2}>
                  <TranslatableTextField
                    disabled={isViewMode}
                    label={t('gallery.title')}
                    tooltip={t('tooltip.gallery.imageTitle')}
                    name="title"
                    errorMessage={elementErrors?.elements?.[innerElement?.backendIndex]?.title}
                    icon="font"
                    value={innerElement.title}
                    onChange={(e, { name, value, language }) =>
                      this.onChangeTranslatableContent(e, {
                        name,
                        value,
                        language,
                        innerElementPosition: innerElement.position,
                      })
                    }
                  />
                  <TranslatableTextField
                    disabled={isViewMode}
                    label={t('gallery.description')}
                    tooltip={t('tooltip.gallery.imageDescription')}
                    name="description"
                    errorMessage={elementErrors?.elements?.[innerElement?.backendIndex]?.description}
                    icon="font"
                    onChange={(e, { name, value, language }) =>
                      this.onChangeTranslatableContent(e, {
                        name,
                        value,
                        language,
                        innerElementPosition: innerElement.position,
                      })
                    }
                    value={innerElement.description}
                  />
                </Grid>
                <Segment>
                  <Grid stackable container columns={isViewMode ? 1 : 2}>
                    <Grid.Column>
                      <Image
                        disabled={innerElement?.file?.delete}
                        src={innerElement?.file?.base64 || innerElement.imageUrl}
                        size="medium"
                        rounded
                      />
                    </Grid.Column>
                    {isViewMode ? null : (
                      <Grid.Column>
                        <Input
                          ref={this.elementImageRef}
                          onChange={e => this.onUploadImage(e, innerElement.position)}
                          type="file"
                          accept={VALID_IMAGE_MIME_TYPES.join(', ')}
                        />
                      </Grid.Column>
                    )}
                  </Grid>
                </Segment>
              </Segment>
            );
          })}
      </div>
    );
  }
}

export default withTranslation(TRANSLATIONS.OFFERS)(GalleryElement);
