const TimelineItemModel = {
  position: 1,
  current: false,
  label: { pl: '' },
  date: { pl: '' },
  content: { pl: '' },
  image: {
    fileName: '',
    base64: '',
    delete: false,
  },
};

export default TimelineItemModel;
