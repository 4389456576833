const TableColumnModel = {
  name: { pl: '-' },
  position: 1,
};

const TableRowModel = {
  position: 1,
  backgroundColor: '#FFFFFF',
  textColor: '#000000',
  cells: [{ position: 1, value: { pl: '-' } }],
};

const AvailableHouseColumnsModel = [
  { name: { pl: 'Nr domu' }, position: 1 },
  { name: { pl: 'Pow. działki [m2]' }, position: 2 },
  { name: { pl: 'Pow. użytkowa domu [m2]' }, position: 3 },
  { name: { pl: 'Cena ofertowa' }, position: 4 },
  { name: { pl: 'Rzut kondygnacji' }, position: 5 },
];

const AvailableHouseRowsModel = [
  {
    position: 1,
    backgroundColor: '#FFFFFF',
    textColor: '#000000',
    cells: [
      { position: 1, value: { pl: '-' } },
      { position: 2, value: { pl: '-' } },
      { position: 3, value: { pl: '-' } },
      { position: 4, value: { pl: '-' } },
      { position: 5, value: { pl: '-' } },
    ],
  },
];

const TableElementModel = {
  columns: [TableColumnModel],
  rows: [TableRowModel],
};

export { TableElementModel, TableColumnModel, TableRowModel, AvailableHouseColumnsModel, AvailableHouseRowsModel };
