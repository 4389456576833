import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import { Loader } from '@shared/layout-components';
import { Button, Message } from 'semantic-ui-react';
import { Card, CardBody, CardHeader, CardTitle, CardToolbar, Table } from '@shared/components';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import useMountWithTranslate from '@shared/utils/useMountWithTranslate';
import { mapErrorCodes } from '@screens/transactions/utils';
import './styles.scss';
import ROUTES from '@shared/consts/routes';
import PERMISSIONS from '@shared/consts/permissions';
import { isEmpty } from 'lodash';
import SCREEN_NAMES from '@shared/consts/screenNames';

const Main = ({
  actions,
  allRoles,
  allRolesStatus,
  allRolesError,
  permittedPermissions,
  isPermissionChecked,
  checkPermissionStatus,
}) => {
  const [t] = useTranslation(TRANSLATIONS.ROLES);
  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  const refreshPage = () => actions.getRolesAction();

  useMountWithTranslate(() => {
    actions.getRolesAction();
  });

  useEffect(() => {
    if (!isEmpty(permittedPermissions) && !isPermissionChecked?.[SCREEN_NAMES.ROLES]) {
      if (
        !isUserPermitted(PERMISSIONS.ADD_PERMISSION_TO_ROLE) ||
        !isUserPermitted(PERMISSIONS.ADD_USER_TO_ROLE) ||
        !isUserPermitted(PERMISSIONS.ROLE_CREATE) ||
        !isUserPermitted(PERMISSIONS.ROLE_DELETE) ||
        !isUserPermitted(PERMISSIONS.ROLE_EDIT) ||
        !isUserPermitted(PERMISSIONS.GET_ROLE_BY_ID) ||
        !isUserPermitted(PERMISSIONS.GET_PERMISSIONS_ASSIGNED_TO_ROLE) ||
        !isUserPermitted(PERMISSIONS.GET_USERS_ASSIGNED_TO_ROLE) ||
        !isUserPermitted(PERMISSIONS.REMOVE_PERMISSION_FROM_ROLE) ||
        !isUserPermitted(PERMISSIONS.REMOVE_USER_FROM_ROLE)
      ) {
        actions.setIsPermissionCheckedAction(SCREEN_NAMES.ROLES);
        actions.checkPermissionAction([
          !isUserPermitted(PERMISSIONS.ADD_PERMISSION_TO_ROLE) && PERMISSIONS.ADD_PERMISSION_TO_ROLE,
          !isUserPermitted(PERMISSIONS.ADD_USER_TO_ROLE) && PERMISSIONS.ADD_USER_TO_ROLE,
          !isUserPermitted(PERMISSIONS.ROLE_CREATE) && PERMISSIONS.ROLE_CREATE,
          !isUserPermitted(PERMISSIONS.ROLE_DELETE) && PERMISSIONS.ROLE_DELETE,
          !isUserPermitted(PERMISSIONS.ROLE_EDIT) && PERMISSIONS.ROLE_EDIT,
          !isUserPermitted(PERMISSIONS.GET_ROLE_BY_ID) && PERMISSIONS.GET_ROLE_BY_ID,
          !isUserPermitted(PERMISSIONS.GET_PERMISSIONS_ASSIGNED_TO_ROLE) &&
            PERMISSIONS.GET_PERMISSIONS_ASSIGNED_TO_ROLE,
          !isUserPermitted(PERMISSIONS.GET_USERS_ASSIGNED_TO_ROLE) && PERMISSIONS.GET_USERS_ASSIGNED_TO_ROLE,
          !isUserPermitted(PERMISSIONS.REMOVE_PERMISSION_FROM_ROLE) && PERMISSIONS.REMOVE_PERMISSION_FROM_ROLE,
          !isUserPermitted(PERMISSIONS.REMOVE_USER_FROM_ROLE) && PERMISSIONS.REMOVE_USER_FROM_ROLE,
        ]);
      }
    }
  }, [permittedPermissions]);

  const columns = () => [
    {
      Header: t('list.id'),
      accessor: 'id',
    },
    {
      Header: t('list.name'),
      accessor: 'name',
      Cell: ({ row }) => {
        return (
          <div>
            <b>{row?.original?.name}</b>
          </div>
        );
      },
    },
    {
      accessor: 'edit',
      Cell: ({ row }) =>
        isUserPermitted(PERMISSIONS.GET_ROLE_BY_ID) ? (
          <Button primary as={Link} to={`/roles/${row?.original?.id}/edit`}>
            {t('actionButtons.edit')}
          </Button>
        ) : null,
    },
  ];

  const renderTable = () => {
    return allRolesStatus === FETCH_STATUS.IDLE ||
      allRolesStatus === FETCH_STATUS.LOADING ||
      checkPermissionStatus === FETCH_STATUS.IDLE ? (
      <Loader />
    ) : allRoles.length > 0 ? (
      <Table hideFilters hideFooter data={allRoles} columns={columns()} />
    ) : (
      <Message>{t('errors.notFoundRoles')}</Message>
    );
  };

  return (
    <Card className="admin-roles">
      <CardHeader>
        <CardTitle title={t('title')} />
        <CardToolbar>
          <Button
            color="black"
            disabled={allRolesStatus === FETCH_STATUS.IDLE || allRolesStatus === FETCH_STATUS.LOADING}
            onClick={refreshPage}
          >
            {t('actionButtons.reload')}
          </Button>
          {isUserPermitted(PERMISSIONS.ROLE_CREATE) ? (
            <Button as={Link} primary to={ROUTES.CREATE_ROLE}>
              {t('actionButtons.add')}
            </Button>
          ) : null}
        </CardToolbar>
      </CardHeader>
      {allRolesError && mapErrorCodes({ errorCode: allRolesError, t })}
      <CardBody transparent>{renderTable()}</CardBody>
    </Card>
  );
};

Main.propTypes = {};

Main.defaultProps = {};

export default Main;
