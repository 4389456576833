import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { Segment, Button, Divider, Form, Grid } from 'semantic-ui-react';
import {
  DatePickerField,
  InputField,
  TranslatableTextField,
  SwitchField,
} from '@screens/offers/NewOfferForm/components';
import './SeriesForm.scss';
import { Link } from 'react-router-dom';
import ROUTES from '@shared/consts/routes';
import { Message, Tooltip } from '@shared/components';
import clsx from 'clsx';
import { bool, func, shape } from 'prop-types';
import { isEmpty } from 'lodash';

const SeriesForm = ({
  isLoading,
  series,
  seriesErrors,
  onRefreshSeries,
  onSaveSeries,
  onCreateSeries,
  isCreateMode,
  isViewMode,
}) => {
  const [t] = useTranslation(TRANSLATIONS.SERIES);
  const [error] = useTranslation(TRANSLATIONS.ERRORS);
  const [seriesData, setSeriesData] = useState({ ...series });

  useEffect(() => {
    if (series?.id && !isEmpty(seriesData)) {
      setSeriesData(series);
    }
  }, [series]);

  const onChangeTranslatableContent = (e, { name, value, language }) =>
    setSeriesData(prevState => ({ ...prevState, [name]: { ...prevState[name], [language]: value } }));

  const onChangeBankingContent = (e, { name, value }) =>
    setSeriesData(prevState => ({ ...prevState, bankInfo: { ...prevState.bankInfo, [name]: value } }));

  const onChangeContent = (e, { name, value }) => setSeriesData(prevState => ({ ...prevState, [name]: value }));

  const onChangeDate = (e, { name, value }) => setSeriesData(prevState => ({ ...prevState, [name]: value }));

  const onSwitchChange = (e, { name }) => setSeriesData(prevState => ({ ...prevState, [name]: !prevState[name] }));

  const onPaypalCredentialsChange = (e, { name, value }) =>
    setSeriesData(prevState => ({
      ...prevState,
      payPalCredentials: {
        ...prevState?.payPalCredentials,
        [name]: value,
      },
    }));

  const onPaypalCredentialsSwitchChange = (e, { name }) =>
    setSeriesData(prevState => ({
      ...prevState,
      payPalCredentials: {
        ...prevState?.payPalCredentials,
        [name]: !prevState?.payPalCredentials[name],
      },
    }));

  const renderMenuButtons = () => {
    return (
      <Grid columns={2}>
        <Grid.Column>
          <h2>{isCreateMode ? t('addSeries') : t('editSeries')}</h2>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Button style={{ marginRight: '5px' }} as={Link} type="button" to={ROUTES.SERIES}>
            {t('actionButtons.goBack')}
          </Button>
          {!isCreateMode && (
            <Button
              disabled={isLoading}
              type="submit"
              secondary
              onClick={() => {
                setSeriesData({});
                onRefreshSeries();
              }}
              style={{ marginRight: '5px' }}
            >
              {t('actionButtons.reload')}
            </Button>
          )}

          {!isCreateMode && !isViewMode && (
            <Button
              style={{ marginRight: '5px' }}
              disabled={isLoading}
              variant="outlined"
              primary
              onClick={() => onSaveSeries({ seriesData })}
            >
              {t('actionButtons.save')}
            </Button>
          )}

          {!isCreateMode && !isViewMode && (
            <Button
              disabled={isLoading}
              variant="outlined"
              color="facebook"
              onClick={() => onSaveSeries({ seriesData, saveAndBack: true })}
            >
              {t('actionButtons.saveAndBack')}
            </Button>
          )}

          {isCreateMode && (
            <Button
              style={{ marginRight: '5px' }}
              disabled={isLoading}
              variant="outlined"
              primary
              onClick={() => onCreateSeries({ seriesData })}
            >
              {t('actionButtons.add')}
            </Button>
          )}

          {isCreateMode && (
            <Button
              disabled={isLoading}
              variant="outlined"
              color="facebook"
              onClick={() => onCreateSeries({ seriesData, saveAndBack: true })}
            >
              {t('actionButtons.addAndBack')}
            </Button>
          )}
        </Grid.Column>
      </Grid>
    );
  };

  return (
    <div>
      {renderMenuButtons()}
      <Segment>
        <Form className="series-form" loading={isLoading}>
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <TranslatableTextField
                name="name"
                label={t('form.name')}
                value={seriesData?.name}
                errorMessage={seriesErrors?.name}
                icon="font"
                onChange={onChangeTranslatableContent}
                disabled={isViewMode}
              />
            </Grid>
          </Form.Group>
          <Divider />
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <TranslatableTextField
                name="displayName"
                label={t('form.displayName')}
                value={seriesData?.displayName}
                errorMessage={seriesErrors?.displayName}
                icon="font"
                onChange={onChangeTranslatableContent}
                disabled={isViewMode}
              />
            </Grid>
          </Form.Group>
          <Divider />
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <DatePickerField
                tooltip={t('tooltip.availableFrom')}
                label={t('form.availableFrom')}
                icon="calendar alternate outline"
                name="availableFrom"
                onChange={onChangeDate}
                value={seriesData?.availableFrom}
                errorMessage={seriesErrors?.availableFrom}
                disabled={isViewMode}
              />
              <DatePickerField
                tooltip={t('tooltip.availableTo')}
                label={t('form.availableTo')}
                icon="calendar alternate"
                name="availableTo"
                onChange={onChangeDate}
                value={seriesData?.availableTo}
                disabled={isViewMode}
                errorMessage={seriesErrors?.availableTo}
              />
            </Grid>
          </Form.Group>
          <Divider />
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <Grid.Column verticalAlign="middle">
                <Form.Field>
                  <h3>
                    <b>{t('form.bankInfo')}</b>
                    <Tooltip>{t('tooltip.bankInfo')}</Tooltip>
                  </h3>
                </Form.Field>
              </Grid.Column>
              <InputField
                disabled={isViewMode}
                name="bankName"
                errorMessage={seriesErrors?.bankName}
                label={t('form.bankName')}
                value={seriesData?.bankInfo?.bankName}
                icon="building outline"
                onChange={onChangeBankingContent}
              />
            </Grid>
          </Form.Group>
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <InputField
                name="accountHolder"
                label={t('form.accountHolder')}
                value={seriesData?.bankInfo?.accountHolder}
                errorMessage={seriesErrors?.bankInfo?.accountHolder}
                icon="money bill alternate"
                onChange={onChangeBankingContent}
                disabled={isViewMode}
              />
              <InputField
                name="accountNumber"
                errorMessage={seriesErrors?.accountNumber}
                label={t('form.accountNumber')}
                value={seriesData?.bankInfo?.accountNumber}
                icon="money bill alternate outline"
                onChange={onChangeBankingContent}
                disabled={isViewMode}
              />
            </Grid>
          </Form.Group>
          <Divider />
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <Grid.Column verticalAlign="middle">
                <Form.Field>
                  <h3>
                    <b>{t('form.sharesInfo')}</b>
                  </h3>
                </Form.Field>
              </Grid.Column>
              <InputField
                type="number"
                name="limit"
                tooltip={t('tooltip.limit')}
                errorMessage={seriesErrors?.limit}
                label={t('form.limit')}
                value={seriesData?.limit}
                icon="level down"
                onChange={onChangeContent}
                disabled={isViewMode}
              />
            </Grid>
          </Form.Group>
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <InputField
                tooltip={t('tooltip.numberOfShares')}
                type="number"
                name="numberOfShares"
                label={t('form.numberOfShares')}
                value={seriesData?.numberOfShares}
                errorMessage={seriesErrors?.numberOfShares}
                icon="numbered list"
                disabled
              />
              <InputField
                type="number"
                name="pricePerShare"
                errorMessage={seriesErrors?.pricePerShare}
                label={t('form.pricePerShare')}
                value={seriesData?.pricePerShare}
                icon="dollar sign"
                disabled={isViewMode}
                onChange={onChangeContent}
              />
            </Grid>
          </Form.Group>
          <Divider />
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <SwitchField
                name="allowOnlinePayments"
                label={t('paypal.allowOnlinePayments')}
                value={seriesData?.allowOnlinePayments}
                errorMessage={seriesErrors?.allowOnlinePayments}
                icon="money bill alternate"
                onChange={onSwitchChange}
                disabled={isViewMode}
                className="d-flex align-items-center justify-content-center flex-column"
              />
              {seriesData?.allowOnlinePayments && (
                <Grid.Column>
                  <Message
                    className={clsx('d-flex align-items-center justify-content-center flex-column h-100')}
                    danger
                  >
                    {error(seriesErrors?.payPalCredentials?.sandbox) ||
                      error(seriesErrors?.payPalCredentials?.production)}
                  </Message>
                </Grid.Column>
              )}
            </Grid>
          </Form.Group>

          {seriesData?.allowOnlinePayments && (
            <>
              <Divider />
              <Form.Group>
                <Grid container stackable columns={2} divided>
                  <SwitchField
                    disabled={isViewMode}
                    name="useDefaultPayPalConfig"
                    errorMessage={seriesErrors?.useDefaultPayPalConfig}
                    label={t('paypal.useDefaultPayPalConfig')}
                    value={seriesData?.useDefaultPayPalConfig}
                    icon="money bill alternate outline"
                    onChange={onSwitchChange}
                    className="d-flex align-items-center justify-content-center flex-column"
                  />
                  {!seriesData?.useDefaultPayPalConfig && (
                    <SwitchField
                      disabled={isViewMode}
                      name="productionMode"
                      errorMessage={seriesErrors?.paypalCredentials?.productionMode}
                      label={t('paypal.productionMode')}
                      value={seriesData?.paypalCredentials?.productionMode}
                      icon="money bill alternate outline"
                      onChange={onPaypalCredentialsSwitchChange}
                      className="d-flex align-items-center justify-content-center flex-column"
                    />
                  )}
                </Grid>
              </Form.Group>
              {!seriesData?.useDefaultPayPalConfig && (
                <>
                  <Divider />
                  <Form.Group>
                    <Grid container stackable columns={2} divided>
                      <InputField
                        disabled={isViewMode}
                        onChange={onPaypalCredentialsChange}
                        value={seriesData?.payPalCredentials?.sandboxClientId}
                        label={t('paypal.sandboxClientId')}
                        name="sandboxClientId"
                        icon="font"
                        errorMessage={seriesErrors?.payPalCredentials?.sandboxClientId}
                      />
                      <InputField
                        disabled={isViewMode}
                        onChange={onPaypalCredentialsChange}
                        value={seriesData?.payPalCredentials?.sandboxClientSecret}
                        errorMessage={seriesErrors?.payPalCredentials?.sandboxClientSecret}
                        label={t('paypal.sandboxClientSecret')}
                        name="sandboxClientSecret"
                        icon="user secret"
                        error
                      />
                    </Grid>
                  </Form.Group>
                  <Divider />
                  <Form.Group>
                    <Grid container stackable columns={2} divided>
                      <InputField
                        disabled={isViewMode}
                        onChange={onPaypalCredentialsChange}
                        value={seriesData?.payPalCredentials?.productionClientId}
                        label={t('paypal.productionClientId')}
                        name="productionClientId"
                        icon="font"
                        errorMessage={seriesErrors?.payPalCredentials?.productionClientId}
                      />
                      <InputField
                        disabled={isViewMode}
                        onChange={onPaypalCredentialsChange}
                        value={seriesData?.payPalCredentials?.productionClientSecret}
                        errorMessage={seriesErrors?.payPalCredentials?.productionClientSecret}
                        label={t('paypal.productionClientSecret')}
                        name="productionClientSecret"
                        icon="user secret"
                        error
                      />
                    </Grid>
                  </Form.Group>
                </>
              )}
            </>
          )}
        </Form>
      </Segment>
      {renderMenuButtons()}
    </div>
  );
};

SeriesForm.propTypes = {
  isLoading: bool,
  series: shape({}),
  seriesErrors: shape({}),
  onRefreshSeries: func,
  onSaveSeries: func,
  onCreateSeries: func,
  isCreateMode: bool,
  isViewMode: bool,
};

SeriesForm.defaultProps = {
  isLoading: false,
  series: {},
  seriesErrors: {},
  onRefreshSeries: () => {},
  onSaveSeries: () => {},
  onCreateSeries: () => {},
  isCreateMode: false,
  isViewMode: false,
};

export default SeriesForm;
