import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { Tooltip } from '@shared/components';
import TRANSLATIONS from '@translations/translationNamespaces';
import styles from './styles.module.scss';

const ThumbImage = ({ src, isVideo }) => {
  const [isError, setIsError] = React.useState(false);
  const { t } = useTranslation(TRANSLATIONS.COMMON);

  const handleError = () => {
    setIsError(true);
  };

  if (isVideo) {
    return (
      <div className={clsx(styles.imageWrapper, isError && styles.imageWrapperError)}>
        <Icon name="video" />
      </div>
    );
  }

  return (
    <div className={clsx(styles.imageWrapper, isError && styles.imageWrapperError)}>
      {isError ? (
        <div className={styles.tooltip}>
          <Tooltip>{t('errorWhileDownloadImage')}</Tooltip>
        </div>
      ) : null}
      {isError || !src ? (
        <Icon name="image" />
      ) : (
        <img className={styles.thumbImage} src={src} alt="" onError={handleError} />
      )}
    </div>
  );
};

ThumbImage.propTypes = {
  src: PropTypes.string,
  isVideo: PropTypes.bool,
};

ThumbImage.defaultProps = {
  src: '',
  isVideo: false,
};

export default ThumbImage;
